import config from './config';
import { toast } from 'react-toastify';
import { getRolePermissions } from './dynamicrolepermissionAction';
import cryptoJs from 'crypto-js';
import { boomiAdminApi, nodeCudApi, nodeGetApi } from './commonAxios';

export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAIL = "USER_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const ROLEPERM_REQUEST = "ROLEPERM_REQUEST";
export const ROLEPERM_SUCCESS = "ROLEPERM_SUCCESS";
export const ROLEPERM_FAIL = "ROLEPERM_FAIL";

export const USER_SESSION_REFRESH_DATE = "USER_SESSION_REFRESH_DATE"

/*=============================================================
                 loginCheck Action
  =============================================================*/
export const loginCheck = (input, setErrorMessage, navigate) => {
    return function (dispatch) {
        dispatch(loginCheckRequest());
        let payload = {
            "Email_Id": input.Email_Id,
            "Password": input.password,
        };
        nodeCudApi.post(`/User/Admin_Login`, payload)
            .then(response => {
                let data = JSON.parse(JSON.stringify(response.data));
                if (data.Tokens) {
                    data.Tokens.expireDate = new Date().setSeconds(new Date().getSeconds() + (data.Tokens.exp - (5 * 60)));
                    const encryptedToken = cryptoJs.AES.encrypt(JSON.stringify(data.Tokens), config.cryptoKay).toString();
                    localStorage.setItem('Token', encryptedToken);
                    dispatch({
                        type: USER_SESSION_REFRESH_DATE,
                        expireDate: data.Tokens.expireDate,
                        expireTime: data.Tokens.exp - (5 * 60)
                    })
                }
                if (data.Response_Status === "Success") {
                    dispatch(getLogedInUser(data.User_EmailID, true, navigate, setErrorMessage, data.User_Session_Id));
                    dispatch(getRolePermissions(data));
                }
                else {
                    setErrorMessage(data.UI_Display_Message);
                    dispatch(loginCheckFail(data.UI_Display_Message, true));
                }
            })
            .catch(error => {
                setErrorMessage("Currently server is not working. Please try again later.");
                dispatch(loginCheckFail("Currently server is not working. Please try again later.", true));
            })
    }
}


export const getLogedInUser = (email, isLogin, navigate, setErrorMessage, User_Session_Id) => {
    return function (dispatch) {
        nodeGetApi.post(`/Super_Admin/User_Get`, {
            "search_by_filter": "",
            "search": "",
            "Records_Filter": "FILTER",
            "Email_Id": email,
            "Mobile_Number": ""
        }
        )
            .then(response => {
                let successResponse = response.data;
                if ((successResponse.Success_Response.Response_Status === "Success") && (Number(successResponse.Success_Response.Is_Data_Exist) > 0)) {
                    let user = response.data.results[0];
                    if (["ADMIN", "EMPLOYEE", "SALES", "CMS", "HR", "QA", "SUPER_ADMIN"].indexOf(user.User_Type) > -1) {
                        user.User_Session_Id = User_Session_Id;
                        dispatch(loginCheckSuccess(user, isLogin));
                        dispatch(loginCheckRoles(user));
                        if (isLogin) {
                            toast('You have successfully logged in.', {
                                position: toast.POSITION.TOP_CENTER, autoClose: 3000,
                            });
                            navigate('/');
                        }
                    }
                    else {
                        if (isLogin) {
                            setErrorMessage("Access denied, please try to login using admin credentials.");
                            dispatch(loginCheckFail("Access denied, please try to login using admin credentials.", isLogin));
                        }
                    }
                } else {
                    if (isLogin) {
                        setErrorMessage(successResponse.UI_Display_Message);
                        dispatch(loginCheckFail(successResponse.UI_Display_Message, isLogin));
                    }
                }
            })
            .catch(error => {
                if (isLogin) {
                    setErrorMessage("Currently server is not working. Please try again later.");
                    dispatch(loginCheckFail("Currently server is not working. Please try again later.", isLogin));
                }
            })
    }
}

/*=============================================================
                 loginOut Action
  =============================================================*/
export const loginOut = (navigate, user) => {
    return function (dispatch) {
        let payload = {
            "User_Session_Id": user.User_Session_Id
        };
        nodeCudApi.post(`/User/Log_Out`, payload)
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    dispatch(loginOutSuccess());
                    localStorage.removeItem('Token');
                    toast('You have successfully logged out.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                    dispatch(loginOutSuccess(user.User_Session_Id));
                    navigate('/login');
                }
            })
    }
}

/*=============================================================
                 forgotCheck Action
  =============================================================*/
export const forgotCheck = (input, forgotResponse) => {
    return function (dispatch) {
        let payload = {
            "Email_Id": input.email,
            "Is_Email_Otp": input.is_email_otp
        };
        boomiAdminApi.post(`/User/Email_Mobile_OTP_Web(USER)`, payload)
            .then(response => {
                let data = response.data;
                if (data.Response_Status === "Success") {
                    forgotResponse(true, '', data.OTP, data.Mobile, data.Is_Email_OTP);
                }
                else {
                    forgotResponse(false, data.UI_Display_Message);
                }
            })
            .catch(error => {
                forgotResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

/*=============================================================
                 forgot Action
  =============================================================*/
export const forgot = (input, forgotResponse) => {
    return function (dispatch) {
        let payload = {
            "Email_Id": input.email,
            "Password": input.password
        };
        boomiAdminApi.post(`/User/Reset_Password`, payload)
            .then(response => {
                let data = response.data;
                if (data.Response_Status === "Success") {
                    forgotResponse(true, '');
                    toast('Password is updated successfully.', {
                        position: toast.POSITION.TOP_CENTER, autoClose: 3000,
                    });
                }
                else {
                    forgotResponse(false, data.UI_Display_Message);
                }
            })
            .catch(error => {
                forgotResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

/*=============================================================
                 loginCheckRequest Action
  =============================================================*/
export const loginCheckRequest = () => {
    return {
        type: USER_REQUEST
    }
}
/*=============================================================
                 loginCheckSuccess Action
  =============================================================*/
export const loginCheckSuccess = (user, isLogin) => {
    return {
        type: USER_SUCCESS,
        data: user,
        login: isLogin
    }
}
/*=============================================================
                 loginCheckFail Action
  =============================================================*/
export const loginCheckFail = (error, isLogin) => {
    return {
        type: USER_FAIL,
        data: error,
        login: isLogin
    }
}

/*=============================================================
                 loginOutSuccess Action
  =============================================================*/
export const loginOutSuccess = () => {
    return {
        type: USER_LOGOUT
    }
}

//roles get success
export const loginCheckRoles = (Roles) => {
    return {
        type: ROLEPERM_SUCCESS,
        data: Roles
    }
}

export const authTokenGenerate = (input, signinResponse) => {
    return function (dispatch) {
        nodeCudApi.post(`/User/access-token`, input)
            .then(response => {
                signinResponse(response.data);
            })
            .catch(error => {
                if (error.response)
                    signinResponse(error.response.data || {});
                else {
                    signinResponse({})
                }
            })
    }
}