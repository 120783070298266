import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from '../../store/validations/is-empty';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import SimpleDialog from '../../components/dialogs/dialog'
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  images: {
    width: 100,
    height: 50
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  }
}));

const headCells = [
  { id: 'Corp_Feq_Asked_Quest_Id', numeric: false, disablePadding: true, label: "FAQ ID" },
  { id: 'Question_Posted_By', numeric: false, disablePadding: false, label: 'Posted By' },
  { id: 'Question', numeric: true, disablePadding: false, label: 'Question' },
  { id: 'Answer', numeric: true, disablePadding: false, label: 'Answer' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allcorporateFaqs,
  order,
  orderBy,
  pagination,
  setPagination,
  createSortHandler,
  handleLimitChange,
  handlePageChange,
  deletecorporateFaqHandler,
  allcorporateFaqsNoFilter,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedcorporateFaqIds, setSelectedcorporateFaqIds] = useState([]);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleSelectAll = event => {
    let newSelectedcorporateFaqIds;
    if (event.target.checked) {
      newSelectedcorporateFaqIds = allcorporateFaqs.map(corporatefaq => corporatefaq.Corp_Feq_Asked_Quest_Id);
    } else {
      newSelectedcorporateFaqIds = [];
    }
    setSelectedcorporateFaqIds(newSelectedcorporateFaqIds);
  };

  const handleSelectOne = (event, id) => {
    setSelectedcorporateFaqIds(selectedcorporateFaqIds.filter(record => {
      for (let i = 0; i < allcorporateFaqsNoFilter.length; i++) {
        if (record == allcorporateFaqsNoFilter[i].Corp_Feq_Asked_Quest_Id) {
          return true;
        }
      }
      return false;
    }))
    const selectedIndex = selectedcorporateFaqIds.indexOf(id);
    let newSelectedcorporateFaqIds = [];
    if (selectedIndex === -1) {
      newSelectedcorporateFaqIds = newSelectedcorporateFaqIds.concat(selectedcorporateFaqIds, id);
    } else if (selectedIndex === 0) {
      newSelectedcorporateFaqIds = newSelectedcorporateFaqIds.concat(
        selectedcorporateFaqIds.slice(1)
      );
    } else if (selectedIndex === selectedcorporateFaqIds.length - 1) {
      newSelectedcorporateFaqIds = newSelectedcorporateFaqIds.concat(
        selectedcorporateFaqIds.slice(0, -1)
      );
    }
    else if (selectedIndex > 0) {
      newSelectedcorporateFaqIds = newSelectedcorporateFaqIds.concat(
        selectedcorporateFaqIds.slice(0, selectedIndex),
        selectedcorporateFaqIds.slice(selectedIndex + 1)
      );
    }
    setSelectedcorporateFaqIds(newSelectedcorporateFaqIds);
  };

  const editcorporateFaqHandler = corporatefaqData => e => {
    navigate('/app/edit-corporatefaq', { state: corporatefaqData });
  };

  const deleteSelectedcorporateFaqHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development..");
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedcorporateFaqIds.filter(record => {
                      for (let i = 0; i < allcorporateFaqsNoFilter.length; i++) {
                        if (record == allcorporateFaqsNoFilter[i].Corp_Feq_Asked_Quest_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allcorporateFaqs.length}
                    color="primary"
                    indeterminate={
                      selectedcorporateFaqIds.filter(record => {
                        for (let i = 0; i < allcorporateFaqsNoFilter.length; i++) {
                          if (record == allcorporateFaqsNoFilter[i].Corp_Feq_Asked_Quest_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedcorporateFaqIds.filter(record => {
                        for (let i = 0; i < allcorporateFaqsNoFilter.length; i++) {
                          if (record == allcorporateFaqsNoFilter[i].Corp_Feq_Asked_Quest_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allcorporateFaqs.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.id !== "_Image" ? (
                      <TableSortLabel style={{ fontSize: "13px", fontWeight: "bold" }}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>) : (<span style={{ fontSize: "13px", fontWeight: "bold" }} > {headCell.label}</span>)
                    }
                  </TableCell>
                ))}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions &nbsp;&nbsp;
                  {selectedcorporateFaqIds.filter(record => {
                    for (let i = 0; i < allcorporateFaqsNoFilter.length; i++) {
                      if (record == allcorporateFaqsNoFilter[i].Corp_Feq_Asked_Quest_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedcorporateFaqIds.filter(record => {
                    for (let i = 0; i < allcorporateFaqsNoFilter.length; i++) {
                      if (record == allcorporateFaqsNoFilter[i].Corp_Feq_Asked_Quest_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0}
                    onClick={deleteSelectedcorporateFaqHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allcorporateFaqs) &&
                (allcorporateFaqs).map(corporatefaq => (
                  <TableRow
                    hover
                    key={corporatefaq.Corp_Feq_Asked_Quest_Id}
                    selected={selectedcorporateFaqIds.indexOf(corporatefaq.Corp_Feq_Asked_Quest_Id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedcorporateFaqIds.indexOf(corporatefaq.Corp_Feq_Asked_Quest_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, corporatefaq.Corp_Feq_Asked_Quest_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell >{corporatefaq.Corp_Feq_Asked_Quest_Id}</TableCell>
                    <TableCell style={{ color: '#1665D8' }} >
                      {corporatefaq.Question_Posted_By?.substring(0, 15)}...
                    </TableCell>
                    <TableCell ><div dangerouslySetInnerHTML={{ __html: corporatefaq.Question?.substring(0, 25) }}></div></TableCell>
                    <TableCell ><div dangerouslySetInnerHTML={{ __html: corporatefaq.Answer?.substring(0, 25) }}></div></TableCell>
                    <TableCell style={{ color: '#1665D8' }}>
                      {corporatefaq.Status}
                    </TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Corporate_FAQ_Write?.Value === "Corporate_FAQ_Write" && RolePermissions?.Corporate_FAQ_Write?.Is_Write === 1) ?
                      <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                          onClick={editcorporateFaqHandler(corporatefaq)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button>
                          <DeleteIcon
                            onClick={deletecorporateFaqHandler(corporatefaq.Corp_Feq_Asked_Quest_Id)}
                            style={{ color: '#9EA0A5' }}
                          />
                        </Button>
                      </TableCell>
                      : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allcorporateFaqs) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" />
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;