import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  CardContent,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
  TextField,
  Divider,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SimpleDialog from '../../../../components/dialogs/dialog'
import isEmpty from '../../../../store/validations/is-empty';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import {
  CopyPartnerproductAction
} from '../../../../store/actions/productpartnerAction';
import {
  getAllPartners,
} from '../../../../store/actions/couponAction';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from 'src/components/pagination/pagination';

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  },
  headerColumn: {
    fontSize: "12px",
    marginRight: "-16px",
    fontWeight: "bold",
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

const headCells = [
  { id: 'Partner_Product_Id', numeric: false, headerTitleAlign: 'center', disablePadding: true, label: 'Partner  Product ID' },
  { id: 'Partner_Name', numeric: false, disablePadding: false, label: 'Partner Name' },
  { id: 'Partner_Price', numeric: false, disablePadding: false, label: 'Partner Price' },
  { id: 'Partner_Type', numeric: false, disablePadding: false, label: 'Partner Type' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
  className,
  allPartners,
  deletePartnerHandler,
  handlePageChange,
  handleLimitChange,
  allPartnersNoFilter,
  order,
  orderBy,
  pagination,
  setPagination,
  createSortHandler,
  state,
  filters,
  callBackPagination,
  RolePermissions,
  roleUserType,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedPartnerIds, setSelectedPartnerIds] = useState([]);
  const [open, setOpen] = useState(false);

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState();

  const handleClose = (value) => {
    setOpen(false);
  };

  const partners = useSelector(state => state.coupon.couponPartners.partners);
  const [payopen, setPayOpen] = React.useState(false);
  const isSubmitted = useSelector(state => state.productPartner.partnerproductCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  const handleClickPayOpen = () => {
    setPayOpen(true);
  };

  const handlePayClose = () => {
    setPayOpen(false);
  };

  // componentDidMount
  useEffect(() => {
    dispatch(getAllPartners());
  }, []);

  /*===============================
            handler     
 =================================*/
  const handleSelectAll = event => {
    let newSelectedPartnerIds;
    if (event.target.checked) {
      newSelectedPartnerIds = allPartners.map(partner => partner.Partner_Product_Id);
    } else {
      newSelectedPartnerIds = [];
    }
    setSelectedPartnerIds(newSelectedPartnerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedPartnerIds.indexOf(id);
    let newselectedPartnerIds = [];
    if (selectedIndex === -1) {
      newselectedPartnerIds = newselectedPartnerIds.concat(selectedPartnerIds, id);
    } else if (selectedIndex === 0) {
      newselectedPartnerIds = newselectedPartnerIds.concat(
        selectedPartnerIds.slice(1)
      );
    }
    else if (selectedIndex === selectedPartnerIds.length - 1) {
      newselectedPartnerIds = newselectedPartnerIds.concat(
        selectedPartnerIds.slice(0, -1)
      );
    }
    else if (selectedIndex > 0) {
      newselectedPartnerIds = newselectedPartnerIds.concat(
        selectedPartnerIds.slice(0, selectedIndex),
        selectedPartnerIds.slice(selectedIndex + 1)
      );
    }
    setSelectedPartnerIds(newselectedPartnerIds);
  };

  const deleteSelectedPartnerHandler = () => async e => {
    setOpen(true);
    setTitle("Alert!");
    setMessage("This feature is under development.");
  };
  const editPartnerHandler = partnerData => e => {
    navigate('/app/edit-productpartner', { state: { state: state, data: partnerData } });
  };
  const viewPartnerHandler = partnerData => e => {
    navigate('/app/view-partner-product', { state: { state: state, data: partnerData } });
  };

  /*=================================
               handler end
   =================================*/
  return (
    <Card className={clsx(classes.root, className)}
      {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1150}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedPartnerIds.filter(record => {
                      for (let i = 0; i < allPartnersNoFilter.length; i++) {
                        if (record == allPartnersNoFilter[i].Partner_Product_Id) {
                          return true;
                        }
                      }
                      return false;
                    }).length === allPartners.length}
                    color="primary"
                    indeterminate={
                      selectedPartnerIds.filter(record => {
                        for (let i = 0; i < allPartnersNoFilter.length; i++) {
                          if (record == allPartnersNoFilter[i].Partner_Product_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length > 0 &&
                      selectedPartnerIds.filter(record => {
                        for (let i = 0; i < allPartnersNoFilter.length; i++) {
                          if (record == allPartnersNoFilter[i].Partner_Product_Id) {
                            return true;
                          }
                        }
                        return false;
                      }).length < allPartners.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel className={classes.headerColumn}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                  (RolePermissions?.Copy_Product_Partner_Write?.Value === "Copy_Product_Partner_Write" && RolePermissions?.Copy_Product_Partner_Write?.Is_Write === 1) ?
                  <TableCell >Copy Products</TableCell>
                  : null}
                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Actions &nbsp;&nbsp;
                  {selectedPartnerIds.filter(record => {
                    for (let i = 0; i < allPartnersNoFilter.length; i++) {
                      if (record == allPartnersNoFilter[i].Partner_Product_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length > 0 && <Button disabled={selectedPartnerIds.filter(record => {
                    for (let i = 0; i < allPartnersNoFilter.length; i++) {
                      if (record == allPartnersNoFilter[i].Partner_Product_Id) {
                        return true;
                      }
                    }
                    return false;
                  }).length <= 0}
                    onClick={deleteSelectedPartnerHandler()}>
                      <DeleteSweepIcon
                        style={{ color: '#ff7a7a', fontSize: 25 }}
                      />
                    </Button>}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(allPartners) &&
                (allPartners).map(partner => (
                  <TableRow
                    hover
                    key={partner.Partner_Product_Id}
                    selected={
                      selectedPartnerIds.indexOf(partner.Partner_Product_Id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedPartnerIds.indexOf(partner.Partner_Product_Id) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, partner.Partner_Product_Id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell  >{partner.Partner_Product_Id}</TableCell>
                    <TableCell >{partner.Partner_Name?.substring(0, 15)}...</TableCell>
                    <TableCell >{partner.Price}</TableCell>
                    <TableCell >{partner.Partner_Type}</TableCell>
                    <TableCell style={{ color: '#1665D8' }}>{partner.Status}</TableCell>
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Copy_Product_Partner_Write?.Value === "Copy_Product_Partner_Write" && RolePermissions?.Copy_Product_Partner_Write?.Is_Write === 1) ?
                      <TableCell >
                        <Tooltip title="Copy Products">
                          <Button disabled={partner.Partner_Type === "Online" ? true : false}
                            style={{ backgroundColor: '#27B6CC' }}
                            variant="contained" onClick={handleClickPayOpen}
                          >
                            <span style={{ color: "white" }}>
                              Copy
                            </span>
                          </Button>
                        </Tooltip>
                        <Dialog open={payopen} fullWidth={true} maxWidth={'sm'}
                          classes={{ paper: classes.dialogPaper }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                          <DialogTitle>
                            <Box display="flex" alignItems="center" style={{ fontSize: 20 + 'px', marginBottom: "5px", marginTop: "-5px" }} >
                              <span>Are you sure you want to copy the Partner Product ?</span>
                              <IconButton style={{ marginTop: "-5px" }} onClick={handlePayClose} className={classes.closeButton}>
                                <CloseIcon />
                              </IconButton>
                            </Box>
                          </DialogTitle>
                          <Divider style={{ marginTop: "-15px" }} />
                          <DialogContent>
                            {(() => {
                              return (
                                <Formik
                                  initialValues={{
                                    Partner_Details_Id: "",
                                    Partner_Product_Id: partner.Partner_Product_Id,
                                    Created_By: loginEmail
                                  }}

                                  onSubmit={values => {
                                    let formValues = JSON.parse(JSON.stringify(values));
                                    dispatch(CopyPartnerproductAction(formValues, filters, pagination, callBackPagination));
                                    setPayOpen(false)
                                  }}
                                >
                                  {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    touched,
                                    values }) => (
                                    <form onSubmit={handleSubmit}>
                                      <ConnectedFocusError />
                                      <Card>
                                        <CardContent>
                                          {/* Partner_Details_Id */}
                                          <Grid item md={12} xs={12}>
                                            <TextField
                                              error={Boolean(touched.Partner_Details_Id && errors.Partner_Details_Id)}
                                              fullWidth
                                              helperText={touched.Partner_Details_Id && errors.Partner_Details_Id}
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              label="Partner Name"
                                              name="Partner_Details_Id"
                                              select
                                              InputLabelProps={{ shrink: true }}
                                              SelectProps={{ native: true }}
                                              value={values.Partner_Details_Id}
                                              variant="outlined"
                                            > <option key="" value="">--Please Select--</option>
                                              {partners?.map(option => (
                                                <option key={option.Partner_Details_Id} value={option.Partner_Details_Id}>
                                                  {option.Name}
                                                </option>
                                              ))}
                                            </TextField>
                                          </Grid>
                                        </CardContent>
                                        <span style={{ color: "red" }}>{errorMessage}</span>
                                        <Box display="flex" p={2}>
                                          <Button
                                            disabled={isSubmitted}
                                            type="submit"
                                            variant="contained"
                                            style={{ backgroundColor: '#27B6CC' }}
                                            reload="true"
                                          >
                                            <span style={{ color: 'white' }}>Save details</span>
                                          </Button>
                                        </Box>
                                      </Card>
                                    </form>
                                  )}
                                </Formik>
                              )
                            })()}
                          </DialogContent>
                        </Dialog>
                      </TableCell>
                      : null}
                    {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                      (RolePermissions?.Product_Partner_Write?.Value === "Product_Partner_Write" && RolePermissions?.Product_Partner_Write?.Is_Write === 1) ?
                      <TableCell style={{ textAlign: "center", }}>
                        <Button
                          onClick={editPartnerHandler(partner)}
                        >
                          <CreateIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button>
                          <DeleteIcon onClick={deletePartnerHandler(partner)}
                            style={{ color: '#9EA0A5' }} />
                        </Button>
                        <Button
                          onClick={viewPartnerHandler(partner)}
                          color="primary" >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                      : null}
                    {RolePermissions?.Product_Partner_Read?.Value === "Product_Partner_Read" && RolePermissions?.Product_Partner_Read?.Is_Read === 1 &&
                      <TableCell style={{ textAlign: "center", }}>
                        <Button
                          onClick={viewPartnerHandler(partner)}
                          color="primary" >
                          <VisibilityIcon style={{ color: '#9EA0A5' }} />
                        </Button>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(allPartners) &&
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
              <img height="400" src="/static/nodata.gif" alt=""/>
            </div>
          </>
        }
      </PerfectScrollbar>
      <Pagination
        pagination={pagination}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
      />
      <SimpleDialog inputMessage={message} titleMsg={title} open={open} onClose={handleClose} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  allPartners: PropTypes.array.isRequired
};

export default Results;