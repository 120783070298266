import config from './config';
import { toast } from 'react-toastify';
import { paginationAllCorpCMSGet } from './node-actions/nodepagination';
import { nodeCudApi, nodeGetApi } from './commonAxios';

export const CONTENTMANAGEMENTS_STATIC_REQUEST = "CONTENTMANAGEMENTS_STATIC_REQUEST";
export const CONTENTMANAGEMENTS_STATIC_SUCCESS = "CONTENTMANAGEMENTS_STATIC_SUCCESS";
export const CONTENTMANAGEMENTS_STATIC_FAIL = "CONTENTMANAGEMENTS_STATIC_FAIL";
export const CONTENTMANAGEMENT_STATIC_GET_REQUEST = "CONTENTMANAGEMENT_STATIC_GET_REQUEST";
export const CONTENTMANAGEMENT_STATIC_GET_SUCCESS = "CONTENTMANAGEMENT_STATIC_GET_SUCCESS";
export const CONTENTMANAGEMENT_STATIC_GET_FAIL = "CONTENTMANAGEMENT_STATIC_GET_FAIL";
export const CONTENTMANAGEMENT_STATIC_CREATE_REQUEST = "CONTENTMANAGEMENT_STATIC_CREATE_REQUEST";
export const CONTENTMANAGEMENT_STATIC_CREATE_SUCCESS = "CONTENTMANAGEMENT_STATIC_CREATE_SUCCESS";
export const CONTENTMANAGEMENT_STATIC_CREATE_FAIL = "CONTENTMANAGEMENT_STATIC_CREATE_FAIL";
export const CONTENTMANAGEMENT_STATIC_UPDATE_REQUEST = "CONTENTMANAGEMENT_STATIC_UPDATE_REQUEST";
export const CONTENTMANAGEMENT_STATIC_UPDATE_SUCCESS = "CONTENTMANAGEMENT_STATIC_UPDATE_SUCCESS";
export const CONTENTMANAGEMENT_STATIC_UPDATE_FAIL = "CONTENTMANAGEMENT_STATIC_UPDATE_FAIL";

/*=============================================================
                  Add CMS Action
===============================================================*/
export const addContentManagementStaticAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: CONTENTMANAGEMENT_STATIC_CREATE_REQUEST
    });
    nodeCudApi.post(`/Corp_Content_Management/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: CONTENTMANAGEMENT_STATIC_CREATE_SUCCESS
                });
                toast('Content added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/corporate-content-management');
            } else {
                dispatch({
                    type: CONTENTMANAGEMENT_STATIC_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: CONTENTMANAGEMENT_STATIC_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};

/*=============================================================
            Update CMS Action
===============================================================*/
export const updateContentManagementStaticAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: CONTENTMANAGEMENT_STATIC_UPDATE_REQUEST
    });
    nodeCudApi.post(`/Corp_Content_Management/Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: CONTENTMANAGEMENT_STATIC_UPDATE_SUCCESS
                });
                toast('Content updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/corporate-content-management');
            } else {
                dispatch({
                    type: CONTENTMANAGEMENT_STATIC_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: CONTENTMANAGEMENT_STATIC_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

/*=============================================================
                  Get All Content Management Action
===============================================================*/
export const getAllContentManagementStaticAction = formData => async dispatch => {
    try {
        dispatch({
            type: CONTENTMANAGEMENTS_STATIC_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Corp_Content_Management/Get?PageNo=1&PageSize=${config.pageSize}&SortBy=CORP_CMS_ID&SortOrder=ASC`, formData);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: CONTENTMANAGEMENTS_STATIC_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: CONTENTMANAGEMENTS_STATIC_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: CONTENTMANAGEMENTS_STATIC_FAIL,
            payload: err
        });
    }
};

/*=============================================================
                  Delete CMS Action
===============================================================*/
export const deleteContentManagementStaticAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
    try {
        let { data } = await nodeCudApi.post(`/Corp_Content_Management/Delete`, formData);
        if (data) {
            toast('Content deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            dispatch(paginationAllCorpCMSGet(filters, pagination, callBackPagination));
        }
    } catch (err) {
    }
};