import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Avatar
} from '@material-ui/core';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import Rating from '@material-ui/lab/Rating';
import {
  addBrandAction,
  uploadBrandImage,
} from './../../store/actions/brandAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const brandOptions = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: "100%",
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddBrand = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState({ Brand_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.brand.brandCreate.isLoading);
  const createdBy = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor
  const [editorState, setEditorState,] = useState(EditorState.createEmpty());
  const [editorStateReviews, setEditorStateReviews] = useState(EditorState.createEmpty());
  const [rhDiscriptionReviewsMin, setRhDiscriptionReviewsMin] = useState(20);
  const [rhDiscriptionReviews, setRhDiscriptionReviews] = useState('');
  const onEditorStateChangeReviews = (editorStateReviews) => {
    setEditorStateReviews(editorStateReviews);
    setRhDiscriptionReviews(draftToHtml(convertToRaw(editorStateReviews.getCurrentContent())));
  };

  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const [rhDiscription, setRhDiscription] = useState('');
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // img upload
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false);
  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    let data = new FormData();
    data.append('file', new Blob([e.target.files[0]]));
    dispatch(
      uploadBrandImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  const values = {
    Brand_Name: "",
    Brand_Link: "",
    Brand_Description: "",
    Brand_Image: "",
    Brand_User_Reviews: "",
    Brand_User_Rating: "",
    Brand_Is_Popular: false,
    Brand_Today_Hot: false,
    Is_Top_Brand: false,
    Created_By: "",
    Brand_Status: "",
    Brand_Esi_Reviews: '',
    Brand_Esi_Rating: 0,
  }

  const Form_Validation = Yup.object().shape({
    Brand_Name: Yup.string().matches(/^[a-zA-Z][\sa-zA-Z]*/, "Please remove special characters & white spaces at the beginning for Name.").min(2, "Name should have atleast 2 characters.").notOneOf([errorMessage.Brand_Name.key, null], errorMessage.Brand_Name.message).required("Name is required."),
    Brand_Link: Yup.string().matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
      'Please provide valid link.').required('Link is required.'),
    Brand_Status: Yup.string().required('Status is required.'),
    Brand_Esi_Rating: Yup.number().required('Please provide ESI Rating.').nullable(),
  })

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};
    values.Brand_Image = imgUrl;
    values.Brand_Description = rhDiscription;
    values.Brand_Esi_Reviews = rhDiscriptionReviews;

    if (!values.Brand_Image) {
      submitTest = false;
      errors.Brand_Image = "Image is required.";
    }
    if (!values.Brand_Description || values.Brand_Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
      submitTest = false;
      errors.Brand_Description = "Description is required.";
    }
    else if (values.Brand_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
      submitTest = false;
      errors.Brand_Description = "Please provide at least " + rhDiscriptionMin + " characters.";
    }
    if (!values.Brand_Esi_Reviews || values.Brand_Esi_Reviews.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
      submitTest = false;
      errors.Brand_Esi_Reviews = "ESI reviews are required.";
    }
    else if (values.Brand_Esi_Reviews.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionReviewsMin) {
      submitTest = false;
      errors.Brand_Esi_Reviews = "Please provide at least " + rhDiscriptionReviewsMin + " characters";
    }
    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      if (values.Brand_Is_Popular) {
        formValues.Brand_Is_Popular = 1;
      } else {
        formValues.Brand_Is_Popular = 0;
      }
      if (values.Brand_Today_Hot) {
        formValues.Brand_Today_Hot = 1;
      } else {
        formValues.Brand_Today_Hot = 0;
      }
      if (values.Is_Top_Brand) {
        formValues.Is_Top_Brand = 1;
      } else {
        formValues.Is_Top_Brand = 0;
      }
      formValues.Created_By = createdBy;
      dispatch(addBrandAction(formValues, navigate, setErrorMessage, setErrors));
    }
  }

  return (
    <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
        setFieldValue,
      }) => (
        <Form>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.Brand_Name && errors.Brand_Name)}
                        label="Name"
                        name="Brand_Name"
                        onChange={handleChange}
                        value={values.Brand_Name}
                        variant="outlined"
                        helperText={<ErrorMessage name="Brand_Name" />}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Brand_Description && errors.Brand_Description}
                      </span>
                    </Grid>

                    {/* ESI Reviews  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        ESI Reviews
                        <EditorContainer editorState={editorStateReviews} onEditorStateChange={onEditorStateChangeReviews} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscriptionReviews || rhDiscriptionReviews.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionReviewsMin) && touched.Brand_Esi_Reviews
                          && errors.Brand_Esi_Reviews}
                      </span>
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <Box display="flex" alignItems="center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Brand_Today_Hot}
                              onChange={handleChange}
                              name="Brand_Today_Hot"
                              color="primary"
                            />
                          }
                          label="Today's Hot"
                        />
                      </Box>
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <Box display="flex" alignItems="center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Brand_Is_Popular}
                              onChange={handleChange}
                              name="Brand_Is_Popular"
                              color="primary"
                            />
                          }
                          label="Is Popular"
                        />
                      </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Box display="flex" alignItems="center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Top_Brand}
                              onChange={handleChange}
                              name="Is_Top_Brand"
                              color="primary"
                            />
                          }
                          label="Is Top Brand"
                        />
                      </Box>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.Brand_Link && errors.Brand_Link)}
                        label="Link"
                        name="Brand_Link"
                        onChange={handleChange}
                        value={values.Brand_Link}
                        variant="outlined"
                        helperText={<ErrorMessage name="Brand_Link" />}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Brand_Status && errors.Brand_Status)}
                        fullWidth
                        placeholder="Status"
                        name="Brand_Status"
                        onChange={handleChange}
                        label="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Brand_Status}
                        variant="outlined"
                        helperText={<ErrorMessage name="Brand_Status" />}
                      >
                        {brandOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <br></br>

                  {/* ESI Rating  */}
                  <Grid item md={4} xs={4}>
                    <Box>ESI Rating</Box>
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <Rating
                      name="Brand_Esi_Rating"
                      onChange={(event, newValue) => {
                        values.Brand_Esi_Rating = newValue;
                        setFieldValue(newValue);
                      }}
                      value={values.Brand_Esi_Rating}
                      size="large"
                    />
                  </Grid>
                  <span style={{ color: "red" }}>
                    {<ErrorMessage name="Brand_Esi_Rating" />}
                  </span>
                </Grid>

                <Grid item md={4} xs={12}>
                  <Card>
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Brand Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>
                    </CardContent>
                  </Card>
                  <span style={{ color: "red" }}>
                    {!imgUrl && touched.Brand_Image && errors.Brand_Image}
                  </span>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>
            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited || uploadPic}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save Details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default AddBrand;