import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    hide: 0,
    link: '/app/category-management',
    createdAt: '',
    description:
      'Category Management is the process of pooling similar products into a singular category and then addressing all business initiatives for that category as a whole.',
    media: '/static/Dashboard/catergory.png',
    title: 'Category Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/subcategory',
    createdAt: '',
    description:
      'A subclass of a category which is itself a category, whose arrows are a restriction of the arrows of the parent category, and whose composition rule is a restriction of the parent category.',
    media: '/static/Dashboard/subcategory.png',
    title: 'SubCategory Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/brand-management',
    createdAt: '',
    description:
      'Brand Management is the functionality where we identify the core value of a specific brand and reflecting the core value among the targeted customers. In Brand Management we can develop brand strategies, brand growth, and expansion by implementing brand plans.',
    media: '/static/Dashboard/brandmanagement.jpg',
    title: 'Brand Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/product-management',
    createdAt: '',
    description:
      'Product Management is a process that focuses on bringing a new product to market or developing an existing one. It starts with an idea of a product that a customer will interact with and ends with the evaluation of the product.',
    media: '/static/Dashboard/productmanagement.png',
    title: 'Product Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/inventory',
    createdAt: '',
    description:
      'Inventory Management refers to the process of ordering, storing and using a company inventory. This includes the management of raw materials, components and finished products, as well as warehousing and processing such items.',
    media: '/static/Dashboard/inventorymanagement.jpg',
    title: 'Inventory Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/coupon-management',
    createdAt: 'Updated 2 hr ago',
    description:
      'A Coupon Management system helps you engage with your customers while giving you real time data of how and when the coupons are being used.',
    media: '/static/Dashboard/coupon_management.jpg',
    title: 'Coupon Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/deal-management',
    createdAt: '',
    description: 'Deal Management is a strategy or tool that gives companies the ability to define deal parameters – including customers history, product status, discount level and operational constraints.',
    media: '/static/Dashboard/Deal_management.png',
    title: 'Deal Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/shop-by-city-management',
    createdAt: '',
    description: 'Shop By City Management is used to refer the Coupons, Deals and Products related to partner, the user can search based on particular city.',
    media: '/static/Dashboard/shop_by_city.jpg',
    title: 'Shop By City Management'
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/banner-management',
    createdAt: '',
    description:
      'Banner Management is the process which contains information about offers and sales about partners to display at UI. Here we implement different operations like creating and updating.',
    media: '/static/Dashboard/Banner_Management.jpg',
    title: 'Banner Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/gift-management',
    createdAt: '',
    description:
      'Gift Management is the process where all the gift information is stored.',
    media: '/static/Dashboard/gift_management.jpg',
    title: 'Gift Management',
    total: ''
  },
];