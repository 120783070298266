import {
  INVENTORY_CREATE_REQUEST,
  INVENTORY_CREATE_SUCCESS,
  INVENTORY_CREATE_FAIL,

  INVENTORY_UPDATE_REQUEST,
  INVENTORY_UPDATE_SUCCESS,
  INVENTORY_UPDATE_FAIL,

  INVENTORY_PRODUCTS_REQUEST,
  INVENTORY_PRODUCTS_SUCCESS,
  INVENTORY_PRODUCTS_FAIL,

  INVENTORY_PARTNERS_REQUEST,
  INVENTORY_PARTNERS_SUCCESS,
  INVENTORY_PARTNERS_FAIL,

  INVENTORY_DETAILS_REQUEST,
  INVENTORY_DETAILS_SUCCESS,
  INVENTORY_DETAILS_FAIL,

  PRODUCTSIZE_INVENTORY_REQUEST,
  PRODUCTSIZE_INVENTORY_SUCCESS,
  PRODUCTSIZE_INVENTORY_FAIL,

  INVENTORY_PRODUCT_MASTER_FAIL,
  INVENTORY_PRODUCT_MASTER_REQUEST,
  INVENTORY_PRODUCT_MASTER_SUCCESS,

  INVENTORY_PARTNERS_FILTER_REQUEST,
  INVENTORY_PARTNERS_FILTER_SUCCESS,
  INVENTORY_PARTNERS_FILTER_FAIL,

} from '../actions/inventoryAction'

const initialState = {
  inventories: { all: [], error: '', isLoading: false },
  inventory: { inventory: {}, error: '', isLoading: false },
  inventoryCreate: { inventory: {}, error: '', isLoading: false },
  inventoryUpdate: { inventory: {}, error: '', isLoading: false },
  inventoryProducts: { products: [], error: '', isLoading: false },
  inventoryPartners: { partners: [], error: '', isLoading: false },
  inventoryDetails: { details: [], error: '', isLoading: false },
  inventoryiMasters: { imasters: [], error: '', isLoading: false },
  inventorySizes: { sizes: [], error: '', isLoading: false },
  inventoryPhysicalPartners: { partners: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INVENTORY_CREATE_REQUEST:
      return { ...state, inventoryCreate: { inventory: {}, error: '', isLoading: true } };
    case INVENTORY_CREATE_SUCCESS:
      return { ...state, inventoryCreate: { inventory: state.inventoryCreate.inventory, error: '', isLoading: false } };
    case INVENTORY_CREATE_FAIL:
      return { ...state, inventoryCreate: { inventory: {}, error: action.payload, isLoading: false } };
    
      case INVENTORY_UPDATE_REQUEST:
      return { ...state, inventoryUpdate: { inventory: {}, error: '', isLoading: true } };
    case INVENTORY_UPDATE_SUCCESS:
      return { ...state, inventoryUpdate: { inventory: state.inventoryUpdate.inventory, error: '', isLoading: false } };
    case INVENTORY_UPDATE_FAIL:
      return { ...state, inventoryUpdate: { inventory: {}, error: action.payload, isLoading: false } };

    case INVENTORY_PRODUCTS_REQUEST:
      return { ...state, inventoryProducts: { products: state.inventoryProducts.products, error: '', isLoading: true }, };
    case INVENTORY_PRODUCTS_SUCCESS:
      return { ...state, inventoryProducts: { products: action.payload, error: '', isLoading: false } };
    case INVENTORY_PRODUCTS_FAIL:
      return { ...state, inventoryProducts: { products: [], error: action.payload, isLoading: false } };

    case INVENTORY_PARTNERS_REQUEST:
      return { ...state, inventoryPartners: { partners: state.inventoryPartners.partners, error: '', isLoading: true }, };
    case INVENTORY_PARTNERS_SUCCESS:
      return { ...state, inventoryPartners: { partners: action.payload, error: '', isLoading: false } };
    case INVENTORY_PARTNERS_FAIL:
      return { ...state, inventoryPartners: { partners: [], error: action.payload, isLoading: false } };

    case INVENTORY_PARTNERS_FILTER_REQUEST:
      return { ...state, inventoryPhysicalPartners: { partners: state.inventoryPhysicalPartners.partners, error: '', isLoading: true }, };
    case INVENTORY_PARTNERS_FILTER_SUCCESS:
      return { ...state, inventoryPhysicalPartners: { partners: action.payload, error: '', isLoading: false } };
    case INVENTORY_PARTNERS_FILTER_FAIL:
      return { ...state, inventoryPhysicalPartners: { partners: [], error: action.payload, isLoading: false } };

    case INVENTORY_DETAILS_REQUEST:
      return { ...state, inventoryDetails: { details: state.inventoryDetails.details, error: '', isLoading: true }, };
    case INVENTORY_DETAILS_SUCCESS:
      return { ...state, inventoryDetails: { details: action.payload, error: '', isLoading: false } };
    case INVENTORY_DETAILS_FAIL:
      return { ...state, inventoryDetails: { details: [], error: action.payload, isLoading: false } };

    case INVENTORY_PRODUCT_MASTER_REQUEST:
      return { ...state, inventoryiMasters: { imasters: state.inventoryiMasters.imasters, error: '', isLoading: true }, };
    case INVENTORY_PRODUCT_MASTER_SUCCESS:
      return { ...state, inventoryiMasters: { imasters: action.payload, error: '', isLoading: false } };
    case INVENTORY_PRODUCT_MASTER_FAIL:
      return { ...state, inventoryiMasters: { imasters: [], error: action.payload, isLoading: false } };

    case PRODUCTSIZE_INVENTORY_REQUEST:
      return { ...state, inventorySizes: { sizes: state.inventorySizes.sizes, error: '', isLoading: true }, };
    case PRODUCTSIZE_INVENTORY_SUCCESS:
      return { ...state, inventorySizes: { sizes: action.payload, error: '', isLoading: false } };
    case PRODUCTSIZE_INVENTORY_FAIL:
      return { ...state, inventorySizes: { sizes: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}