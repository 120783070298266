import Base64 from "src/hooks/Base64";
const BASEURL = process.env.REACT_APP_BASEURL
const BOOMI_HOST = process.env.REACT_APP_BOOMI_HOST
const USERNAME_BOOMI = process.env.REACT_APP_USERNAME_BOOMI
const PASSWORD_BOOMI = process.env.REACT_APP_PASSWORD_BOOMI
const BOOMI_HOST_DEL = process.env.REACT_APP_BOOMI_HOST_DEL
const NODE_HOST = process.env.REACT_APP_NODE_HOST
const USERNAME_NODE = process.env.REACT_APP_USERNAME_NODE
const PASSWORD_NODE = process.env.REACT_APP_PASSWORD_NODE
const NODE_USER_HOST = process.env.REACT_APP_NODE_USER_HOST
const USERNAME_USER_NODE = process.env.REACT_APP_USERNAME_USER_NODE
const PASSWORD_USER_NODE = process.env.REACT_APP_PASSWORD_USER_NODE
const PARTNER_HOST = process.env.REACT_APP_PARTNER_HOST
const CORP_HOST = process.env.REACT_APP_CORP_HOST
const BOOMI_ADMIN_HOST = process.env.REACT_APP_BOOMI_HOST_ADMIN
const USERNAME_BOOMI_ADMIN = process.env.REACT_APP_USERNAME_BOOMI_ADMIN
const PASSWORD_BOOMI_ADMIN = process.env.REACT_APP_PASSWORD_BOOMI_ADMIN
const NODE_ADMIN_HOST = process.env.REACT_APP_NODE_HOST_ADMIN
const USERNAME_NODE_ADMIN = process.env.REACT_APP_USERNAME_NODE_ADMIN
const PASSWORD_NODE_ADMIN = process.env.REACT_APP_PASSWORD_NODE_ADMIN
const PAGESIZE = process.env.REACT_APP_PAGESIZE
const CRYPTO_KEY = process.env.CRYPTO_KEY

export const PROD_SERVER = false;
export const DEVELOP_MEDE = false;
export const SERVER_HOST = PROD_SERVER ? BOOMI_HOST : NODE_HOST;
export const AUTH_USERNAME = USERNAME_BOOMI;
export const AUTH_PASSWORD = PASSWORD_BOOMI;
export const AUTH_USERNAME_NODE = USERNAME_NODE;
export const AUTH_PASSWORD_NODE = PASSWORD_NODE;

const config = {
    //base url
    BASEURL: BASEURL,
    //admin url
    adminurl: BOOMI_ADMIN_HOST,
    // url
    url: BOOMI_HOST,
    //del url
    delurl: BOOMI_HOST_DEL,
    // corp url
    corpurl: CORP_HOST,
    // partner url
    parturl: PARTNER_HOST,
    //node cud url
    nodecudurl: NODE_ADMIN_HOST,
    //page size
    pageSize: PAGESIZE,
    //crypto key
    cryptoKay: process.env.NODE_ENV === 'production' ? 'Meepa!sa@2023' : 'Meepaisa@2023',
    // auth
    auth: {
        username: USERNAME_BOOMI,
        password: PASSWORD_BOOMI,
    },
    // headersCommon
    headersCommon: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        'Authorization': "Basic " + Base64.btoa(USERNAME_BOOMI + ":" + PASSWORD_BOOMI)
    },
    //admin auth
    adminauth: {
        username: USERNAME_BOOMI_ADMIN,
        password: PASSWORD_BOOMI_ADMIN,
    },
    // admin headersCommon
    adminheadersCommon: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "x-api-key":USERNAME_BOOMI_ADMIN === "meepaisa_prod@easystepin-6WV1UR" ? "32764aeb-d65c-40d9-9f60-38366df554e4" : "5ae396d1-97f8-4a95-b127-8ca7fb12acc5",
        'Authorization': "Basic " + Base64.btoa(USERNAME_BOOMI_ADMIN + ":" + PASSWORD_BOOMI_ADMIN)
    },

    // nodeauth
    nodeurl: NODE_HOST,
    nodeauth: {
        username: USERNAME_NODE,
        password: PASSWORD_NODE,
    },
    //nodeheaders
    nodeheadersCommon: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        'Authorization': "Basic " + Base64.btoa(USERNAME_NODE + ":" + PASSWORD_NODE)
    },

    // node-user-auth
    nodeUserUrl: NODE_USER_HOST,
    nodeUserAuth: {
        username: USERNAME_USER_NODE,
        password: PASSWORD_USER_NODE,
    },
    //node-user-headers
    nodeUserHeadersCommon: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        'Authorization': "Basic " + Base64.btoa(USERNAME_USER_NODE + ":" + PASSWORD_USER_NODE)
    },

    //node cud auth
    nodecudauth: {
        username: USERNAME_NODE_ADMIN,
        password: PASSWORD_NODE_ADMIN,
    },
    //node cud headersCommon
    nodecudheadersCommon: {
        "Content-Type": "application/json",
        'Authorization': "Basic " + Base64.btoa(USERNAME_NODE_ADMIN + ":" + PASSWORD_NODE_ADMIN)
    },

};

export default config;