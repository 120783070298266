import {

    SINGLE_ORDER_REQUEST,
    SINGLE_ORDER_SUCCESS,
    SINGLE_ORDER_FAIL,

    ORDER_UPDATE_REQUEST,
    ORDER_UPDATE_SUCCESS,
    ORDER_UPDATE_FAIL,

    ORDERS_ESIDELIPERSONS_REQUEST,
    ORDERS_ESIDELIPERSONS_SUCCESS,
    ORDERS_ESIDELIPERSONS_FAIL,

    ORDERS_PARTNERDELIPERSONS_REQUEST,
    ORDERS_PARTNERDELIPERSONS_SUCCESS,
    ORDERS_PARTNERDELIPERSONS_FAIL,

    PARTNER_ADDRESS_REQUEST,
    PARTNER_ADDRESS_SUCCESS,
    PARTNER_ADDRESS_FAIL,

    ORDER_ADDRESS_REQUEST,
    ORDER_ADDRESS_SUCCESS,
    ORDER_ADDRESS_FAIL,

    ORDER_ITEM_ADDRESS_REQUEST,
    ORDER_ITEM_ADDRESS_SUCCESS,
    ORDER_ITEM_ADDRESS_FAIL,

    ORDER_PAYMENT_AUTH_REQUEST,
    ORDER_PAYMENT_AUTH_SUCCESS,
    ORDER_PAYMENT_AUTH_FAIL,

    ORDER_PARTNER_PAYMENT_REQUEST,
    ORDER_PARTNER_PAYMENT_SUCCESS,
    ORDER_PARTNER_PAYMENT_FAIL,

    ORDER_SHIP_REQUEST,
    ORDER_SHIP_SUCCESS,
    ORDER_SHIP_FAIL,

    ORDER_SHIP_CANCEL_REQUEST,
    ORDER_SHIP_CANCEL_SUCCESS,
    ORDER_SHIP_CANCEL_FAIL,

    ORDER_SHIP_NIMBUS_REQUEST,
    ORDER_SHIP_NIMBUS_SUCCESS,
    ORDER_SHIP_NIMBUS_FAIL,

    ORDER_SHIP_NIMBUS_CANCEL_REQUEST,
    ORDER_SHIP_NIMBUS_CANCEL_SUCCESS,
    ORDER_SHIP_NIMBUS_CANCEL_FAIL,

} from '../actions/ordersAction'


const initialState = {
    singleorder: { all: {}, error: '', isLoading: false },
    orderUpdate: { order: {}, error: '', isLoading: false },
    Orderesidelipersons: { all: [], error: '', isLoading: false },
    Orderpartnerdelipersons: { all: [], error: '', isLoading: false },
    Orderpartneraddress: { all: [], error: '', isLoading: false },
    OrderAddress: { all: [], error: '', isLoading: false },
    OrderItemAddress: { all: [], error: '', isLoading: false },
    orderPaymentAuthCreate: { paymentauth: {}, error: '', isLoading: false },
    orderPartnerPaymentCreate: { partnerpayment: {}, error: '', isLoading: false },
    orderShipCreate: { ordership: {}, error: '', isLoading: false },
    orderShipCancel: { ordershipcancel: {}, error: '', isLoading: false },
    orderShipnimbusCreate: { ordershipnimbus: {}, error: '', isLoading: false },
    orderShipnimbusCancel: { ordershipnimbuscancel: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case SINGLE_ORDER_REQUEST:
            return { ...state, singleorder: { all: {}, error: '', isLoading: true } };
        case SINGLE_ORDER_SUCCESS:
            return { ...state, singleorder: { all: action.payload, error: '', isLoading: false } };
        case SINGLE_ORDER_FAIL:
            return { ...state, singleorder: { all: {}, error: action.payload, isLoading: false } };

        case ORDER_UPDATE_REQUEST:
            return { ...state, orderUpdate: { orders: {}, error: '', isLoading: true } };
        case ORDER_UPDATE_SUCCESS:
            return { ...state, orderUpdate: { orders: action.payload, error: '', isLoading: false } };
        case ORDER_UPDATE_FAIL:
            return { ...state, orderUpdate: { orders: {}, error: action.payload, isLoading: false } };

        case ORDERS_ESIDELIPERSONS_REQUEST:
            return { ...state, Orderesidelipersons: { all: state.Orderesidelipersons.all, error: '', isLoading: true } };
        case ORDERS_ESIDELIPERSONS_SUCCESS:
            return { ...state, Orderesidelipersons: { all: action.payload, error: '', isLoading: false } };
        case ORDERS_ESIDELIPERSONS_FAIL:
            return { ...state, Orderesidelipersons: { all: [], error: action.payload, isLoading: false } };

        case ORDERS_PARTNERDELIPERSONS_REQUEST:
            return { ...state, Orderpartnerdelipersons: { all: state.Orderpartnerdelipersons.all, error: '', isLoading: true } };
        case ORDERS_PARTNERDELIPERSONS_SUCCESS:
            return { ...state, Orderpartnerdelipersons: { all: action.payload, error: '', isLoading: false } };
        case ORDERS_PARTNERDELIPERSONS_FAIL:
            return { ...state, Orderpartnerdelipersons: { all: [], error: action.payload, isLoading: false } };

        case PARTNER_ADDRESS_REQUEST:
            return { ...state, Orderpartneraddress: { all: state.Orderpartneraddress.all, error: '', isLoading: true } };
        case PARTNER_ADDRESS_SUCCESS:
            return { ...state, Orderpartneraddress: { all: action.payload, error: '', isLoading: false } };
        case PARTNER_ADDRESS_FAIL:
            return { ...state, Orderpartneraddress: { all: [], error: action.payload, isLoading: false } };

        case ORDER_ADDRESS_REQUEST:
            return { ...state, OrderAddress: { all: state.OrderAddress.all, error: '', isLoading: true } };
        case ORDER_ADDRESS_SUCCESS:
            return { ...state, OrderAddress: { all: action.payload, error: '', isLoading: false } };
        case ORDER_ADDRESS_FAIL:
            return { ...state, OrderAddress: { all: [], error: action.payload, isLoading: false } };

        case ORDER_ITEM_ADDRESS_REQUEST:
            return { ...state, OrderItemAddress: { all: state.OrderItemAddress.all, error: '', isLoading: true } };
        case ORDER_ITEM_ADDRESS_SUCCESS:
            return { ...state, OrderItemAddress: { all: action.payload, error: '', isLoading: false } };
        case ORDER_ITEM_ADDRESS_FAIL:
            return { ...state, OrderItemAddress: { all: [], error: action.payload, isLoading: false } };

        case ORDER_PAYMENT_AUTH_REQUEST:
            return { ...state, orderPaymentAuthCreate: { paymentauth: {}, error: '', isLoading: true } };
        case ORDER_PAYMENT_AUTH_SUCCESS:
            return { ...state, orderPaymentAuthCreate: { paymentauth: state.orderPaymentAuthCreate.paymentauth, error: '', isLoading: false } };
        case ORDER_PAYMENT_AUTH_FAIL:
            return { ...state, orderPaymentAuthCreate: { paymentauth: {}, error: action.payload, isLoading: false } };

        case ORDER_PARTNER_PAYMENT_REQUEST:
            return { ...state, orderPartnerPaymentCreate: { partnerpayment: {}, error: '', isLoading: true } };
        case ORDER_PARTNER_PAYMENT_SUCCESS:
            return { ...state, orderPartnerPaymentCreate: { partnerpayment: state.orderPartnerPaymentCreate.partnerpayment, error: '', isLoading: false } };
        case ORDER_PARTNER_PAYMENT_FAIL:
            return { ...state, orderPartnerPaymentCreate: { partnerpayment: {}, error: action.payload, isLoading: false } };

        case ORDER_SHIP_REQUEST:
            return { ...state, orderShipCreate: { ordership: {}, error: '', isLoading: true } };
        case ORDER_SHIP_SUCCESS:
            return { ...state, orderShipCreate: { ordership: state.orderShipCreate.ordership, error: '', isLoading: false } };
        case ORDER_SHIP_FAIL:
            return { ...state, orderShipCreate: { ordership: {}, error: action.payload, isLoading: false } };

        case ORDER_SHIP_CANCEL_REQUEST:
            return { ...state, orderShipCancel: { ordershipcancel: {}, error: '', isLoading: true } };
        case ORDER_SHIP_CANCEL_SUCCESS:
            return { ...state, orderShipCancel: { ordershipcancel: action.payload, error: '', isLoading: false } };
        case ORDER_SHIP_CANCEL_FAIL:
            return { ...state, orderShipCancel: { ordershipcancel: {}, error: action.payload, isLoading: false } };

        case ORDER_SHIP_NIMBUS_REQUEST:
            return { ...state, orderShipnimbusCreate: { ordershipnimbus: {}, error: '', isLoading: true } };
        case ORDER_SHIP_NIMBUS_SUCCESS:
            return { ...state, orderShipnimbusCreate: { ordershipnimbus: state.orderShipnimbusCreate.ordershipnimbus, error: '', isLoading: false } };
        case ORDER_SHIP_NIMBUS_FAIL:
            return { ...state, orderShipnimbusCreate: { ordershipnimbus: {}, error: action.payload, isLoading: false } };

        case ORDER_SHIP_NIMBUS_CANCEL_REQUEST:
            return { ...state, orderShipnimbusCancel: { ordershipnimbuscancel: {}, error: '', isLoading: true } };
        case ORDER_SHIP_NIMBUS_CANCEL_SUCCESS:
            return { ...state, orderShipnimbusCancel: { ordershipnimbuscancel: action.payload, error: '', isLoading: false } };
        case ORDER_SHIP_NIMBUS_CANCEL_FAIL:
            return { ...state, orderShipnimbusCancel: { ordershipnimbuscancel: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}