import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Avatar,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  updateCategory,
  uploadCategoryImage,
  uploadmobileimage,
  getAllStoretypes
} from './../../store/actions/categoryAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import CircularProgress from '@mui/material/CircularProgress';

const statusOption = [
  {
    value: '',
    label: '--Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const categoryforOption = [
 
  {
    value: 'REGULAR',
    label: 'REGULAR'
  },
  {
    value: 'MFH',
    label: 'MFH'
  }
];
const useStyles = makeStyles(() => ({
  images: {
    width: "100%",
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditCategory = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  let { state } = location;

  //all store type names
  useEffect(() => {
    dispatch(getAllStoretypes());
  }, []);

  //get store type names
  const storetypenames = useSelector(state => state.category.storetypes.all);

  const [errorMessage, setErrorMessage] = useState({ Category_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmitted = useSelector(state => state.category.categoryUpdate.isLoading);
  const modifiedBy = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor
  const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Description).contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(state.Description);

  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // img upload
  const [image, setImage] = useState({ preview: state.Category_Image_Web, raw: state.Category_Image_Web });
  const [imgUrl, setImgUrl] = useState(state.Category_Image_Web);
  const [uploadPic, setUploadPic] = useState(false);
  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadCategoryImage(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  // img upload1
  const [image1, setImage1] = useState({ preview: state.Category_Image_Mobile, raw: state.Category_Image_Mobile });
  const [imgUrl1, setImgUrl1] = useState(state.Category_Image_Mobile);
  const [uploadPic1, setUploadPic1] = useState(false);
  const callBackUploadImgae1 = data => {
    if (data) {
      setImgUrl1(data.File_URL);
    }
    setUploadPic1(false);
  };

  const handleImage1 = e => {
    setUploadPic1(true);
    e.preventDefault();
    setImage1({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      uploadmobileimage(new Blob([e.target.files[0]]), callBackUploadImgae1)
    );
  };

  const handleRemoveImage1 = e => {
    setImgUrl1('');
    setImage1({
      preview: '',
      raw: ''
    });
  };

  return (
    <Formik
      initialValues={{
        Category_Image_Web: imgUrl,
        Category_Image_Mobile: imgUrl1,
        Category_Id: state.Category_Id ? state.Category_Id : "",
        Category_Name: state.Category_Name ? state.Category_Name : "",
        Description: state.Description ? state.Description : "",
        Status: state.Status ? state.Status : "",
        Is_Top_Category: state.Is_Top_Category > 0 ? true : false,
        Is_Farmer_Category: state.Is_Farmer_Category > 0 ? true : false,
        Modified_By: modifiedBy,
        Store_Type_Name: state.Store_Type_Name ? state.Store_Type_Name : "",
        Category_For: state.Category_For ? state.Category_For : "",
      }}
      validationSchema={
        Yup.object().shape({
          Category_Name: Yup.string().min(3, "Category Name must be at least 3 characters.").notOneOf([errorMessage.Category_Name.key, null], errorMessage.Category_Name.message).required('Category Name is required.'),
          Status: Yup.string().required('Status is required.'),
          Store_Type_Name: Yup.string().required('Store Type Name is required.'),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        values.Category_Image_Web = imgUrl;
        values.Category_Image_Mobile = imgUrl1;
        values.Description = rhDiscription;
        if (!values.Category_Image_Web) {
          submitTest = false;
          errors.Category_Image_Web = " Web Image is required.";
        }
        if (!values.Category_Image_Mobile) {
          submitTest = false;
          errors.Category_Image_Mobile = "Mobile Image is required.";
        }
        if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Description = "Description is required.";
        }
        else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
        }
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          if (values.Is_Top_Category) {
            formValues.Is_Top_Category = 1;
          } else {
            formValues.Is_Top_Category = 0;
          }
          if (values.Is_Farmer_Category) {
            formValues.Is_Farmer_Category = 1;
          } else {
            formValues.Is_Farmer_Category = 0;
          }
          dispatch(updateCategory(formValues, navigate, setErrorMessage, setErrors));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Category_Name && errors.Category_Name)}
                        fullWidth
                        helperText={touched.Category_Name && errors.Category_Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Category Name"
                        name="Category_Name"
                        value={values.Category_Name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Store_Type_Name && errors.Store_Type_Name)}
                        fullWidth
                        helperText={touched.Store_Type_Name && errors.Store_Type_Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Store Type Name"
                        name="Store_Type_Name"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Store_Type_Name}
                        variant="outlined"
                      ><option key="" value="">--Please Select--</option>
                        {storetypenames?.sort((a, b) => a.Store_Type_Name?.localeCompare(b.Store_Type_Name))?.map(option => (
                          <option key={option.Store_Type_Name} value={option.Store_Type_Name}>
                            {option.Store_Type_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                      </span>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Status"
                        name="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>


                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Category_For && errors.Category_For)}
                        fullWidth
                        helperText={touched.Category_For && errors.Category_For}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Category For"
                        name="Category_For"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Category_For}
                        variant="outlined"
                      >
                        {categoryforOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box display="flex" alignItems="center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Top_Category}
                              onChange={handleChange}
                              name="Is_Top_Category"
                              color="primary"
                            />
                          }
                          label="Is Top Category"
                        />
                      </Box>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Box display="flex" alignItems="center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={values.Is_Farmer_Category}
                              onChange={handleChange}
                              name="Is_Farmer_Category"
                              color="primary"
                            />
                          }
                          label="Is Farmer Category"
                        />
                      </Box>
                    </Grid>

                  </Grid>
                </Grid>

                {/* upload photo */}
                <Grid item md={4} xs={12}>
                  <Card>
                    &nbsp;Web Image
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Brand Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>
                    </CardContent>
                  </Card><br />
                  <span style={{ color: "red" }}>
                    {!imgUrl && touched.Category_Image_Web && errors.Category_Image_Web}
                  </span>

                  <Card>
                    &nbsp;Mobile Image
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image1.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Brand Upload"
                            src={image1.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image1.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage1}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage1}
                        />}
                        <br />
                      </Box>
                    </CardContent>
                  </Card><br />

                  <span style={{ color: "red" }}>
                    {!imgUrl1 && touched.Category_Image_Mobile && errors.Category_Image_Mobile}
                  </span>
                </Grid>
              </Grid>

            </CardContent>
            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmitted ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmitted || uploadPic || uploadPic1}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditCategory.propTypes = {
  className: PropTypes.string
};

export default EditCategory;