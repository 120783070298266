import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createInventoryAction,
  getAllProducts,
  getAllMasters,
  getAllSizes,
  getAllDetails,
  getAllPartnerProducts
} from './../../store/actions/inventoryAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const AddInventory = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /// componentDidMount
  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getAllDetails());
    dispatch(getAllMasters());
    dispatch(getAllSizes());
    dispatch(getAllPartnerProducts());
  }, []);
  
  const [masterid, setMasterid] = useState('');
  const [partnerid, setPartnerid] = useState('');
  const [Product, setProduct] = useState('');

  const isSubmited = useSelector(state => state.inventory.inventoryCreate.isLoading);
  const errorMessage = useSelector(state => state.inventory.inventoryCreate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const inventoryPhysicalPartners = useSelector(state => state.inventory.inventoryPhysicalPartners.partners);
  const sizes = useSelector(state => state.inventory.inventorySizes.sizes);

  const setType = (value) => {
    let formData = {
      Records_Filter: "FILTER",
      Product_Master_Id: value,
      Partner_Details_Id: partnerid
    };
    dispatch(getAllSizes(formData));
  }

  return (
    <Formik
      initialValues={{
        Partner_Product_Id: '',
        Product_Id: '',
        Partner_Details_Id: '',
        Product_Master_Id: '',
        Product_Size: '',
        Inventory_Version: '',
        Availability_Status: '',
        Available_Date: '',
        Total_Stock: '',
        Created_By: loginEmail,
      }}
      validationSchema={
        Yup.object().shape({
          Partner_Product_Id: Yup.string().required('Partner with Product Name is required.'),
          Product_Master_Id: Yup.string().required('Product Master Name is required.'),
          Product_Id: Yup.string().required('Product Name is required.'),
          Partner_Details_Id: Yup.string().required('Partner Name is required.'),
          Product_Size: Yup.string().required('Product Size is required.'),
          Inventory_Version: Yup.string().required('Inventory Version is required.'),
          Available_Date: Yup.date().min(Date(), "Date must not be past date.").required('Available Date is required.'),
          Total_Stock: Yup.number().min(1, "Total Stock must be at least 1.").required('Total Stock is required.'),
          Availability_Status: Yup.string().required('Availability Status is required.'),
        })
      }
      onSubmit={(values) => {
        let formData = JSON.parse(JSON.stringify(values));
        dispatch(createInventoryAction(formData, navigate));
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    {/* Partner Product Id */}
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        freeSolo
                        options={inventoryPhysicalPartners}
                        groupBy={(option) => option.firstLetter}
                        id="combo-box-demo"
                        getOptionLabel={(option, index) => option?.Partner_Name + " - " + option.Product_Name || option.Partner_Product_Id}
                        renderInput={(params) => <TextField {...params}
                          error={Boolean(touched.Partner_Product_Id && errors.Partner_Product_Id)}
                          helperText={touched.Partner_Product_Id && errors.Partner_Product_Id}
                          onBlur={handleBlur}
                          label="Partner with Product Name"
                          name="Partner_Product_Id"
                          variant="outlined"
                          value={values.Partner_Product_Id}
                          onChange={() => {
                            dispatch(getAllPartnerProducts(params.inputProps.value))
                          }}
                          InputProps={{
                            ...params.InputProps,
                            autoComplete: 'off', // disable autocomplete and autofill,
                            startAdornment: (
                              <React.Fragment>
                                <SearchIcon color="inherit" size={20}></SearchIcon>
                                {params.InputProps.startAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />}
                        onChange={(e, value) => {
                          handleChange(e);
                          setProduct(value?.Partner_Product_Id ? value.Partner_Product_Id : "");
                          setFieldValue("Partner_Product_Id", value?.Partner_Product_Id ? value.Partner_Product_Id : "");
                        }}
                      />
                    </Grid>

                    {/*Product_Master_Id*/}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Product_Master_Id && errors.Product_Master_Id)}
                        fullWidth
                        helperText={touched.Product_Master_Id && errors.Product_Master_Id}
                        onBlur={handleBlur}
                        onChange={(e) => { handleChange(e); setMasterid(e.target.value); setType(e.target.value) }}
                        label="Product Master Name"
                        name="Product_Master_Id"
                        value={masterid}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        <option key="" value="">--Please Select--</option>
                        {inventoryPhysicalPartners?.filter(option => option.Partner_Product_Id === Product).map(option => (
                          <option value={option.Product_Master_Id} key={option.Product_Master_Id}>{option.Product_Master_Name}</option>
                        ))}

                      </TextField>
                    </Grid>
                    {/* Product Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Product_Id && errors.Product_Id)}
                        fullWidth
                        helperText={touched.Product_Id && errors.Product_Id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Product Name"
                        name="Product_Id"
                        value={values.Product_Id}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        <option key="" value="">--Please Select--</option>
                        {inventoryPhysicalPartners?.filter(option => option.Partner_Product_Id === Product).map(option => (
                          <option value={option.Product_Id} key={option.Product_Id}>{option.Product_Name}</option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Partner Detail Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Partner_Details_Id && errors.Partner_Details_Id)}
                        fullWidth
                        helperText={touched.Partner_Details_Id && errors.Partner_Details_Id}
                        onBlur={handleBlur}
                        onChange={(e) => { handleChange(e); setPartnerid(e.target.value) }}
                        label="Partner Name"
                        name="Partner_Details_Id"
                        value={partnerid}
                        variant="outlined"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                      >
                        <option key="" value="">--Please Select--</option>
                        {inventoryPhysicalPartners?.filter(option => option.Partner_Product_Id === Product).map(option => (
                          <option value={option.Partner_Details_Id} key={option.Partner_Details_Id}>{option.Partner_Name}</option>
                        ))}

                      </TextField>
                    </Grid>
                    {/* Product_Size */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Product_Size && errors.Product_Size)}
                        fullWidth
                        helperText={touched.Product_Size && errors.Product_Size}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Product Size"
                        name="Product_Size"
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        value={values.Product_Size}
                        variant="outlined"
                      >
                        <option key="" value="">--Please Select--</option>
                        {sizes.map(option => (
                          <option value={option.Size} key={option.Product_Size_Id}>{option.Size + " - " + option.Size_Measurement}</option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Inventory Version */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Inventory_Version && errors.Inventory_Version)}
                        fullWidth
                        helperText={touched.Inventory_Version && errors.Inventory_Version}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Inventory Version"
                        name="Inventory_Version"
                        value={values.Inventory_Version}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>
                    {/* Total Stock */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Total_Stock && errors.Total_Stock)}
                        fullWidth
                        helperText={touched.Total_Stock && errors.Total_Stock}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        label="Total Stock"
                        name="Total_Stock"
                        value={values.Total_Stock}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>
                    {/* Available Date */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Available_Date && errors.Available_Date)}
                        fullWidth
                        helperText={touched.Available_Date && errors.Available_Date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="datetime-local"
                        label="Available Date"
                        name="Available_Date"
                        value={values.Available_Date}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                      </TextField>
                    </Grid>
                    {/* Availability Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Availability_Status && errors.Availability_Status)}
                        fullWidth
                        helperText={touched.Availability_Status && errors.Availability_Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Availability Status"
                        name="Availability_Status"
                        select
                        SelectProps={{ native: true }}
                        value={values.Availability_Status}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span></Box>
            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#27B6CC' }}
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddInventory.propTypes = {
  className: PropTypes.string
};

export default AddInventory;