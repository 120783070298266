import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import NavItem from './NavItem';
import { useSelector } from 'react-redux';
import {
  TwoWheeler as TwoWheelerIcon,
  ManageAccounts as ManageAccountsIcon,
  Store as StoreIcon,
  SettingsSuggest as SettingsSuggestIcon,
  Business as BusinessIcon,
  Assessment as AssessmentIcon,
  Dashboard as DashboardIcon,
  ShoppingCart as ShoppingCartIcon,
  Campaign as CampaignIcon,
  LocalShipping as LocalShippingIcon,
  PeopleAlt as PeopleAltIcon,
  TextFields as TextFieldsIcon,
  Handshake as HandshakeIcon,
  RoomPreferences as RoomPreferencesIcon,
  Payments as PaymentsIcon,
  Language as LanguageIcon,
  Diversity3 as Diversity3Icon,
  Hub as HubIcon
} from '@mui/icons-material';

const items = [
  {
    href: '/app/reports',
    icon: AssessmentIcon,
    title: 'Reports',
    hide: 0
  },
  {
    href: '/app/company-profile-management',
    icon: RoomPreferencesIcon,
    title: 'Company Settings',
    hide: 0
  },
  {
    href: '/app/esi-main-centre',
    icon: DashboardIcon,
    title: 'ESI Main Centre',
    hide: 0
  },
  {
    href: '/app/partner-main-page',
    icon: HandshakeIcon,
    title: 'Partner Management',
    hide: 0
  },
  {
    href: '/app/user-main-centre',
    icon: PeopleAltIcon,
    title: 'Users Management',
    hide: 0
  },
  {
    href: '/app/order-management',
    icon: ShoppingCartIcon,
    title: 'Order Management',
    hide: 0
  },
  {
    href: '/app/promotion-management',
    icon: CampaignIcon,
    title: 'Promotion Management',
    hide: 0
  },
  {
    href: '/app/content-management',
    icon: TextFieldsIcon,
    title: 'Content Management',
    hide: 0
  },
  {
    href: '/app/delivery-management',
    icon: LocalShippingIcon,
    title: 'Delivery Management',
    hide: 0
  },
  {
    href: '/app/corporate-management',
    icon: BusinessIcon,
    title: 'Corporate Management',
    hide: 0
  },
  {
    href: '/app/store-type-management',
    icon: StoreIcon,
    title: 'Store Type Management',
    hide: 0
  },
  {
    href: '/app/user-rides-maincentre',
    icon: TwoWheelerIcon,
    title: 'User Ride Management',
    hide: 0
  },
  {
    href: '/app/services-maincentre',
    icon: ManageAccountsIcon,
    title: 'Services Management',
    hide: 0
  },
  {
    href: '/app/bidding-management',
    icon: Diversity3Icon,
    title: 'Bidding Management',
    hide: 0
  },
  {
    href: '/app/meepaisa-fashion-hub',
    icon: HubIcon,
    title: 'Meepaisa Fashion Hub',
    hide: 0
  },
  {
    href: '/app/razorpaypayments-maincentre',
    icon: PaymentsIcon,
    title: 'Razorpay Payments',
    hide: 0
  },
  {
    href: '/app/globalcoupon-main-centre',
    icon: LanguageIcon,
    title: 'Meepaisa Global Coupons',
    hide: 0
  },
  {
    href: '/app/settings-management',
    icon: SettingsSuggestIcon,
    title: 'Settings',
    hide: 0
  }
];


const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 60,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const user = useSelector(state => state.auth.user);
  const userprofile = useSelector(state => state.auth.user?.Profile_Pic_Path ? state.auth.user?.Profile_Pic_Path : '../../../../static/defaultlogo.png');
  const RolePermissions = useSelector(state => state.rolepermission.Roles);
  const rolePermission = eval(RolePermissions)

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={userprofile}
          to="/app/account"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user?.First_Name} {user?.Last_name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user?.User_Type}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              item={item}
              rolePermission={rolePermission}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;