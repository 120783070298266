import config from './config';
import { toast } from 'react-toastify';
import { nodeCudApi, nodeGetApi, boomiAdminApi } from './commonAxios';

export const INVENTORY_CREATE_REQUEST = "INVENTORY_CREATE_REQUEST";
export const INVENTORY_CREATE_SUCCESS = "INVENTORY_CREATE_SUCCESS";
export const INVENTORY_CREATE_FAIL = "INVENTORY_CREATE_FAIL";

export const INVENTORY_UPDATE_REQUEST = "INVENTORY_UPDATE_REQUEST";
export const INVENTORY_UPDATE_SUCCESS = "INVENTORY_UPDATE_SUCCESS";
export const INVENTORY_UPDATE_FAIL = "INVENTORY_UPDATE_FAIL";

export const INVENTORY_PRODUCTS_REQUEST = "INVENTORY_PRODUCTS_REQUEST";
export const INVENTORY_PRODUCTS_SUCCESS = "INVENTORY_PRODUCTS_SUCCESS";
export const INVENTORY_PRODUCTS_FAIL = "INVENTORY_PRODUCTS_FAIL";

export const INVENTORY_PARTNERS_REQUEST = "INVENTORY_PARTNERS_REQUEST";
export const INVENTORY_PARTNERS_SUCCESS = "INVENTORY_PARTNERS_SUCCESS";
export const INVENTORY_PARTNERS_FAIL = "INVENTORY_PARTNERS_FAIL";

export const INVENTORY_DETAILS_REQUEST = "INVENTORY_DETAILS_REQUEST";
export const INVENTORY_DETAILS_SUCCESS = "INVENTORY_DETAILS_SUCCESS";
export const INVENTORY_DETAILS_FAIL = "INVENTORY_DETAILS_FAIL";

export const INVENTORY_PRODUCT_MASTER_REQUEST = "INVENTORY_PRODUCT_MASTER_REQUEST";
export const INVENTORY_PRODUCT_MASTER_SUCCESS = "INVENTORY_PRODUCT_MASTER_SUCCESS";
export const INVENTORY_PRODUCT_MASTER_FAIL = "INVENTORY_PRODUCT_MASTER_FAIL";

export const PRODUCTSIZE_INVENTORY_REQUEST = "PRODUCTSIZE_INVENTORY_REQUEST";
export const PRODUCTSIZE_INVENTORY_SUCCESS = "PRODUCTSIZE_INVENTORY_SUCCESS";
export const PRODUCTSIZE_INVENTORY_FAIL = "PRODUCTSIZE_INVENTORY_FAIL";

export const INVENTORY_PARTNERS_FILTER_REQUEST = "INVENTORY_PARTNERS_FILTER_REQUEST";
export const INVENTORY_PARTNERS_FILTER_SUCCESS = "INVENTORY_PARTNERS_FILTER_SUCCESS";
export const INVENTORY_PARTNERS_FILTER_FAIL = "INVENTORY_PARTNERS_FILTER_FAIL";

/*=============================================================
                  Add Inventory Action
===============================================================*/
export const createInventoryAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: INVENTORY_CREATE_REQUEST
  });
  nodeCudApi.post(`/Inventory/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: INVENTORY_CREATE_SUCCESS
        });
        toast('Inventory added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/inventory');
      } else {
        dispatch({
          type: INVENTORY_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: INVENTORY_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Update Inventory Action
===============================================================*/
export const updateInventoryAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: INVENTORY_UPDATE_REQUEST
  });
  nodeCudApi.post(`/Inventory/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: INVENTORY_UPDATE_SUCCESS
        });
        toast('Inventory updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/inventory');
      } else {

        dispatch({
          type: INVENTORY_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: INVENTORY_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
               Get all products
 ===============================================================*/
export const getAllProducts = () => async dispatch => {
  try {
    let formData = {
      search_by_filter: "",
      search: "",
      Records_Filter: "ALL",
      Partner_Details_Id: "",
      Product_Id: ""
    };
    dispatch({
      type: INVENTORY_PRODUCTS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Products/Get_Admin?PageNo=1&PageSize=20&SortBy=Product_Id&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: INVENTORY_PRODUCTS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: INVENTORY_PRODUCTS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: INVENTORY_PRODUCTS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
               Get all partners
===============================================================*/
export const getAllPartners = (formData) => async dispatch => {
  try {
    dispatch({
      type: INVENTORY_PARTNERS_REQUEST
    });
    let { data } = await boomiAdminApi.post(`/Partners/Physical_Partner_Product_GET`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: INVENTORY_PARTNERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: INVENTORY_PARTNERS_SUCCESS,
          payload: data.Partner_Product
        });
      }
    }
  } catch (err) {
    dispatch({
      type: INVENTORY_PARTNERS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
               Get all details
===============================================================*/
export const getAllDetails = () => async dispatch => {
  try {
    let formData = {
      "Records_Filter": "FILTERS",
      "Buisness_Type": "('GROCERY', 'FOOD', 'RETAIL', 'MFH', 'FARMER')",
      "Status": "Active"
    };
    dispatch({
      type: INVENTORY_DETAILS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Partners/Physical_Partner_Profile_Get_Admin?PageNo=1&PageSize=20&SortBy=PARTNER_DETAILS_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.successResponse?.Is_Data_Exist === '0') {
        dispatch({
          type: INVENTORY_DETAILS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: INVENTORY_DETAILS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: INVENTORY_DETAILS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
               Get All Sizes Action
===============================================================*/
export const getAllSizes = (formData) => async dispatch => {
  try {
    dispatch({
      type: PRODUCTSIZE_INVENTORY_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Products/Product_Remain_Size_Get`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCTSIZE_INVENTORY_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCTSIZE_INVENTORY_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCTSIZE_INVENTORY_FAIL,
      payload: err
    });
  }
};

/*=============================================================
             Get All Masters Action
===============================================================*/
export const getAllMasters = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Status: "Active",
      search_by_filter: "",
      search: "",
    };
    dispatch({
      type: INVENTORY_PRODUCT_MASTER_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Products/Product_Master_Get_Admin?PageNo=1&PageSize=20&SortBy=PRODUCT_MASTER_ID&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: INVENTORY_PRODUCT_MASTER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: INVENTORY_PRODUCT_MASTER_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: INVENTORY_PRODUCT_MASTER_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Get all partners
===============================================================*/
export const getAllPartnerProducts = (ProductName) => async dispatch => {
  try {
    let formData = {
      search: "",
      search_by_filter: "",
      Product_Name: ProductName,
      Records_Filter: "FILTER"
    };
    dispatch({
      type: INVENTORY_PARTNERS_FILTER_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Products/Physical_Partner_Product_Get_By_Product_Name?PageNo=1&PageSize=20&SortBy=Product_Name&SortOrder=ASC`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: INVENTORY_PARTNERS_FILTER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: INVENTORY_PARTNERS_FILTER_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: INVENTORY_PARTNERS_FILTER_FAIL,
      payload: err
    });
  }
};

//Node API
export const getInventoryAction = (formData, callBackInventoryData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(`$/Inventory/Get_Admin`, formData);
    if (data) {
      callBackInventoryData(data)
    }
  } catch (err) {
  }
};