import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
} from '@material-ui/core';
import React, { useState, } from 'react';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik'
import EditorContainer from '../../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import {
  addcorporateFaqAction,
} from '../../../store/actions/corporatefaqAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const faqOptions = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddCorporateFAQ = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSubmited = useSelector(state => state.corporatefaq.corporatefaqCreate.isLoading);
  const errorMessage = useSelector(state => state.corporatefaq.corporatefaqCreate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  const [editorStateFeatures, setEditorStateFeatures] = useState(EditorState.createEmpty());
  const [rhDiscriptionFeaturesMin, setRhDiscriptionFeaturesMin] = useState(20);
  const [rhDiscriptionFeatures, setRhDiscriptionFeatures] = useState('');
  const onEditorStateChangeFeatures = (editorStateFeatures) => {
    setEditorStateFeatures(editorStateFeatures);
    setRhDiscriptionFeatures(draftToHtml(convertToRaw(editorStateFeatures.getCurrentContent())));
  };

  const values = {
    Question: "",
    Answer: '',
    Question_Posted_By: loginEmail,
    Status: ""
  }

  const Form_Validation = Yup.object().shape({
    Status: Yup.string().required('Status is required.'),
    Question: Yup.string().required('Question is required.'),
  })

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};
    values.Answer = rhDiscriptionFeatures;

    if (!values.Answer || values.Answer.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
      submitTest = false;
      errors.Answer = "Answer is required.";
    }
    else if (values.Answer.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionFeaturesMin) {
      submitTest = false;
      errors.Answer = "Please provide at least " + rhDiscriptionFeaturesMin + " characters";
    }
    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      dispatch(addcorporateFaqAction(formValues, navigate));
    }
  }

  return (
    <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
      }) => (
        <Form>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Question && errors.Question)}
                        fullWidth
                        helperText={touched.Question && errors.Question}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Question"
                        name="Question"
                        value={values.Question}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Answer
                        <EditorContainer editorState={editorStateFeatures} onEditorStateChange={onEditorStateChangeFeatures} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscriptionFeatures || rhDiscriptionFeatures.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionFeaturesMin) && touched.Answer && errors.Answer}
                      </span>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        placeholder="Status"
                        name="Status"
                        onChange={handleChange}
                        label="Status"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        helperText={<ErrorMessage name="Status" />}
                      >
                        {faqOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>

            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default AddCorporateFAQ;