import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    hide: 0,
    link: '/app/corporatefaq-management',
    createdAt: '',
    description: 'It stands for frequently-asked questions, and it is a page on a website that gives quick answers to customer questions. The idea is to keep the answers short and direct so that people find info quickly.',
    media: '/static/Dashboard/faq.jpg',
    title: 'Corporate FAQs',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/corporate-content-management',
    createdAt: '',
    description: 'A content management system (CMS) is an application that is used to manage web content, allowing multiple contributors to create, edit and publish. Content in a CMS is typically stored in a database and displayed in a presentation layer based on a set of templates. ',
    media: '/static/Dashboard/Cms.jpg',
    title: 'Corporate Content Management',
    total: ''
  },
];