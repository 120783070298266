import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createNewseventAction,
} from './../../store/actions/newseventsAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import EditorContainer from '../../components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '--Please Select--',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  root: {},
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const AddNewsevent = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const errorMessage = useSelector(state => state.newsevents.newseventCreate.error);
  const isSubmitted = useSelector(state => state.newsevents.newseventCreate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);

  // Richtext editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const [rhDiscription, setRhDiscription] = useState('');
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // Richtext editor (meta description)
  const [editorStateMeta, setEditorStateMeta] = useState(EditorState.createEmpty());
  const [rhDiscriptionMetaMin, setRhDiscriptionMetawMin] = useState(20);
  const [rhDiscriptionMeta, setRhDiscriptionMeta] = useState('');
  const onEditorStateChangeMeta = (editorStateMeta) => {
    setEditorStateMeta(editorStateMeta);
    setRhDiscriptionMeta(draftToHtml(convertToRaw(editorStateMeta.getCurrentContent())));
  };

  return (
    <Formik
      initialValues={{
        Author: '',
        Created_BY: loginEmail,
        Description: '',
        Meta_Description: '',
        Meta_Keywords: '',
        Short_Description: '',
        Title: '',
        Status: ''
      }}
      validationSchema={
        Yup.object().shape({
          Author: Yup.string().min(3, "Author should have atleast 3 characters.").required(" Author is required."),
          Title: Yup.string().required(' Title is required.'),
          Meta_Keywords: Yup.string().required(' Meta Keywords are required.'),
          Short_Description: Yup.string().required(' Short Description is required.'),
          Status: Yup.string().required('Status  is required.'),
        })
      }
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        values.Description = rhDiscription;
        values.Meta_Description = rhDiscriptionMeta;

        if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Description = "Description is required.";
        }
        else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
          submitTest = false;
          errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
        }
        if (!values.Meta_Description || values.Meta_Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
          submitTest = false;
          errors.Meta_Description = "Meta Description is required.";
        }
        else if (values.Meta_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMetaMin) {
          submitTest = false;
          errors.Meta_Description = "Please provide at least " + rhDiscriptionMetaMin + " characters.";
        }
        setErrors(errors);
        if (submitTest) {
          let formValues = JSON.parse(JSON.stringify(values));
          dispatch(createNewseventAction(formValues, navigate));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Author && errors.Author)}
                        fullWidth
                        helperText={touched.Author && errors.Author}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Author"
                        name="Author"
                        value={values.Author}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Title && errors.Title)}
                        fullWidth
                        helperText={touched.Title && errors.Title}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Title"
                        name="Title"
                        value={values.Title}
                        variant="outlined"
                      />

                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.Short_Description && errors.Short_Description)}
                        fullWidth
                        helperText={touched.Short_Description && errors.Short_Description}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Short Description"
                        name="Short_Description"
                        value={values.Short_Description}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Discription  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                      </span>
                    </Grid>
                    {/* Meta  Description  */}
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Meta Description
                        <EditorContainer editorState={editorStateMeta} onEditorStateChange={onEditorStateChangeMeta} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscriptionMeta || rhDiscriptionMeta.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMetaMin) && touched.Meta_Description && errors.Meta_Description}
                      </span>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Meta_Keywords && errors.Meta_Keywords)}
                        fullWidth
                        helperText={touched.Meta_Keywords && errors.Meta_Keywords}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Meta Keywords"
                        name="Meta_Keywords"
                        value={values.Meta_Keywords}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Status && errors.Status)}
                        fullWidth
                        helperText={touched.Status && errors.Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label=" Status"
                        name="Status"
                        select
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmitted ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmitted}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddNewsevent.propTypes = {
  className: PropTypes.string
};

export default AddNewsevent;