import { nodeGetApi, boomiAdminApi } from './commonAxios';

export const NOTIFICATIONS_REQUEST = "NOTIFICATIONS_REQUEST";
export const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";
export const NOTIFICATIONS_FAIL = "NOTIFICATIONS_FAIL";

export const NOTIFICATION_GET_REQUEST = "NOTIFICATION_GET_REQUEST";
export const NOTIFICATION_GET_SUCCESS = "NOTIFICATION_GET_SUCCESS";
export const NOTIFICATION_GET_FAIL = "NOTIFICATION_GET_FAIL";

export const NOTIFICATION_UPDATE_REQUEST = "NOTIFICATION_UPDATE_REQUEST";
export const NOTIFICATION_UPDATE_SUCCESS = "NOTIFICATION_UPDATE_SUCCESS";
export const NOTIFICATION_UPDATE_FAIL = "NOTIFICATION_UPDATE_FAIL";

/*=============================================================
                  Update Notification Action
===============================================================*/
export const UpdateNotificationAction = (formData) => async dispatch => {
  dispatch({
    type: NOTIFICATION_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Notifications/Notification_Update_Flow`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: NOTIFICATION_UPDATE_SUCCESS
        });
        const formData = {
          Records_Filter: 'ALL',
          Partner_Details_Id: "",
        };
        dispatch(getAllNotificationAction(formData));
      } else {
        dispatch({
          type: NOTIFICATION_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: NOTIFICATION_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                  Get All Notification Action
===============================================================*/
export const getAllNotificationAction = (formData, callBackData) => async (dispatch) => {
  try {
    let { data } = await nodeGetApi.post(`/Notifications/Get_Admin`, formData);
    if (data) {
      callBackData(data);
    }
    if (data) {
      let dataresponse = { data: data, form: formData }
      if (dataresponse?.data?.Success_Response?.Is_Data_Exist === '0') {
        callBackData(data);
      } else {
        callBackData(data);
      }
    }
  } catch (err) {
  }
};