import { toast } from 'react-toastify';
import { paginationNewsEvents } from './node-actions/nodepagination';
import { nodeCudApi} from './commonAxios';
export const NEWSEVENTS_CREATE_REQUEST = "NEWSEVENTS_CREATE_REQUEST";
export const NEWSEVENTS_CREATE_SUCCESS = "NEWSEVENTS_CREATE_SUCCESS";
export const NEWSEVENTS_CREATE_FAIL = "NEWSEVENTS_CREATE_FAIL";
export const NEWSEVENTS_UPDATE_REQUEST = "NEWSEVENTS_UPDATE_REQUEST";
export const NEWSEVENTS_UPDATE_SUCCESS = "NEWSEVENTS_UPDATE_SUCCESS";
export const NEWSEVENTS_UPDATE_FAIL = "NEWSEVENTS_UPDATE_FAIL";


/*=============================================================
                  Add Newsevent Action
===============================================================*/
export const createNewseventAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: NEWSEVENTS_CREATE_REQUEST
  });
  nodeCudApi.post(`/News/News_Events_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: NEWSEVENTS_CREATE_SUCCESS
        });
        toast('News & Events added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/News-and-Events');
      } else {
        dispatch({
          type: NEWSEVENTS_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: NEWSEVENTS_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};
/*=============================================================
                edit Newsaevent Action
===============================================================*/
export const updateNewseventsAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: NEWSEVENTS_UPDATE_REQUEST
  });
  nodeCudApi.post(`/News/News_Event_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: NEWSEVENTS_UPDATE_SUCCESS
        });
        toast('News & Events updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/News-and-Events');
      } else {
        dispatch({
          type: NEWSEVENTS_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: NEWSEVENTS_CREATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

/*=============================================================
                   Delete Newsevents Action
===============================================================*/
export const deleteNewseventsAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCudApi.post(`/News/News_Event_Delete`, formData);
    if (data) {
      toast('News & Events  deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationNewsEvents(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};