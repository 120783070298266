import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    hide: 0,
    link: '/app/News-and-Events',
    createdAt: '',
    description: 'News is information about current events. There’s a reason you’re posting news—make your point early and clearly. People tend to skim stories on the Web. When the news in the story is hard to find, readers may get frustrated and stop reading.',
    media: '/static/Dashboard/News_and_event.jpg',
    title: 'News & Events',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/faq-management',
    createdAt: '',
    description: 'A manageable feature, faculty, function, process, service or discipline that represents an ability to perform something which yields an expected set of results and is capable of further advancement or development, specifically pertaining to the area of Frequently Asked Question (FAQ) Management.',
    media: '/static/Dashboard/faq.jpg',
    title: "FAQ Management",
    total: ""
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/contents-management',
    createdAt: '',
    description: 'A content management system (CMS) is an application that is used to manage web content, allowing multiple contributors to create, edit and publish. Content in a CMS is typically stored in a database and displayed in a presentation layer based on a set of templates. ',
    media: '/static/Dashboard/Cms.jpg',
    title: 'Content Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/testimonial-management',
    createdAt: '',
    description: 'Testimonials should include the persons name, title, company and picture. The least credible testimonials include just the persons initials. The testimonial text is the same. ',
    media: '/static/Dashboard/Testimonial2.jpg',
    title: 'Testimonial Management',
    total: ''
  },
];