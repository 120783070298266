import config from './config';
import { toast } from 'react-toastify';
import { nodeGetApi, boomiAdminApi } from './commonAxios';

export const SINGLE_ORDER_REQUEST = 'SINGLE_ORDER_REQUEST';
export const SINGLE_ORDER_SUCCESS = "SINGLE_ORDER_SUCCESS";
export const SINGLE_ORDER_FAIL = "SINGLE_ORDER_FAIL";

export const ORDER_UPDATE_REQUEST = "ORDER_UPDATE_REQUEST";
export const ORDER_UPDATE_SUCCESS = "ORDER_UPDATE_SUCCESS";
export const ORDER_UPDATE_FAIL = "ORDER_UPDATE_FAIL";

export const ORDERS_ESIDELIPERSONS_REQUEST = "ORDERS_ESIDELIPERSONS_REQUEST";
export const ORDERS_ESIDELIPERSONS_SUCCESS = "ORDERS_ESIDELIPERSONS_SUCCESS";
export const ORDERS_ESIDELIPERSONS_FAIL = "ORDERS_ESIDELIPERSONS_FAIL";

export const ORDERS_PARTNERDELIPERSONS_REQUEST = "ORDERS_PARTNERDELIPERSONS_REQUEST";
export const ORDERS_PARTNERDELIPERSONS_SUCCESS = "ORDERS_PARTNERDELIPERSONS_SUCCESS";
export const ORDERS_PARTNERDELIPERSONS_FAIL = "ORDERS_PARTNERDELIPERSONS_FAIL";

export const PARTNER_ADDRESS_REQUEST = "PARTNER_ADDRESS_REQUEST";
export const PARTNER_ADDRESS_SUCCESS = "PARTNER_ADDRESS_SUCCESS";
export const PARTNER_ADDRESS_FAIL = "PARTNER_ADDRESS_FAIL";

export const ORDER_ADDRESS_REQUEST = 'ORDER_ADDRESS_REQUEST';
export const ORDER_ADDRESS_SUCCESS = "ORDER_ADDRESS_SUCCESS";
export const ORDER_ADDRESS_FAIL = "ORDER_ADDRESS_FAIL";

export const ORDER_ITEM_ADDRESS_REQUEST = 'ORDER_ITEM_ADDRESS_REQUEST';
export const ORDER_ITEM_ADDRESS_SUCCESS = "ORDER_ITEM_ADDRESS_SUCCESS";
export const ORDER_ITEM_ADDRESS_FAIL = "ORDER_ITEM_ADDRESS_FAIL";

export const ORDER_PAYMENT_AUTH_REQUEST = "ORDER_PAYMENT_AUTH_REQUEST";
export const ORDER_PAYMENT_AUTH_SUCCESS = "ORDER_PAYMENT_AUTH_SUCCESS";
export const ORDER_PAYMENT_AUTH_FAIL = "ORDER_PAYMENT_AUTH_FAIL";

export const ORDER_PARTNER_PAYMENT_REQUEST = "ORDER_PARTNER_PAYMENT_REQUEST";
export const ORDER_PARTNER_PAYMENT_SUCCESS = "ORDER_PARTNER_PAYMENT_SUCCESS";
export const ORDER_PARTNER_PAYMENT_FAIL = "ORDER_PARTNER_PAYMENT_FAIL";

export const ORDER_SHIP_REQUEST = "ORDER_SHIP_REQUEST";
export const ORDER_SHIP_SUCCESS = "ORDER_SHIP_SUCCESS";
export const ORDER_SHIP_FAIL = "ORDER_SHIP_FAIL";

export const ORDER_SHIP_CANCEL_REQUEST = "ORDER_SHIP_CANCEL_REQUEST";
export const ORDER_SHIP_CANCEL_SUCCESS = "ORDER_SHIP_CANCEL_SUCCESS";
export const ORDER_SHIP_CANCEL_FAIL = "ORDER_SHIP_CANCEL_FAIL";

export const ORDER_SHIP_NIMBUS_REQUEST = "ORDER_SHIP_NIMBUS_REQUEST";
export const ORDER_SHIP_NIMBUS_SUCCESS = "ORDER_SHIP_NIMBUS_SUCCESS";
export const ORDER_SHIP_NIMBUS_FAIL = "ORDER_SHIP_NIMBUS_FAIL";

export const ORDER_SHIP_NIMBUS_CANCEL_REQUEST = "ORDER_SHIP_NIMBUS_CANCEL_REQUEST";
export const ORDER_SHIP_NIMBUS_CANCEL_SUCCESS = "ORDER_SHIP_NIMBUS_CANCEL_SUCCESS";
export const ORDER_SHIP_NIMBUS_CANCEL_FAIL = "ORDER_SHIP_NIMBUS_CANCEL_FAIL";

//Get Single Order Node API
export const getsingleOrderAction = (formData) => async (dispatch) => {
    try {
        dispatch({
            type: SINGLE_ORDER_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Super_Admin/Order_GetFlow`, formData);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: SINGLE_ORDER_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: SINGLE_ORDER_SUCCESS,
                    payload: data?.results[0]
                });
            }
        }
    } catch (err) {
        dispatch({
            type: SINGLE_ORDER_FAIL,
            payload: err
        });
    }
};

//update order status
export const updateOrderStatusAction = (formData, ID, navigate, setErrorMessage, setErrors) => async dispatch => {
    dispatch({
        type: ORDER_UPDATE_REQUEST
    });
    setErrorMessage({ global: { key: '', message: '' } });
    boomiAdminApi.put(`/Super_Admin/Order_UpdateFlow`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: ORDER_UPDATE_SUCCESS
                });
                toast('Details updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                if (ID === '1') {
                    navigate('/app/order-history')
                } else {
                    navigate('/app/shipping')
                }
            } else {
                const errors = {};
                setErrors(errors)
                setErrorMessage({ global: { key: '', message: successResponse.UI_Display_Message ? successResponse.UI_Display_Message : '' } });
                dispatch({
                    type: ORDER_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: ORDER_UPDATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};

//get all ESI delivery persons
export const getAllESIDeliveryPersonsAction = (formData) => async (dispatch) => {
    try {
        dispatch({
            type: ORDERS_ESIDELIPERSONS_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Super_Admin/Delivery_Person_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=DEL_USER_EMAIL_ID&SortOrder=ASC`, formData);
        if (data) {
            if (data.Success_Response?.Is_Data_Exist === '0') {
                dispatch({
                    type: ORDERS_ESIDELIPERSONS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: ORDERS_ESIDELIPERSONS_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: ORDERS_ESIDELIPERSONS_FAIL,
            payload: err
        });
    }
};

//get all partner delivery persons
export const getAllPartnerDeliveryPersonsAction = (formData) => async (dispatch) => {
    try {
        dispatch({
            type: ORDERS_PARTNERDELIPERSONS_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Super_Admin/Delivery_Person_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=DEL_USER_EMAIL_ID&SortOrder=ASC`, formData);
        if (data) {
            if (data.Success_Response?.Is_Data_Exist === '0') {
                dispatch({
                    type: ORDERS_PARTNERDELIPERSONS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: ORDERS_PARTNERDELIPERSONS_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: ORDERS_PARTNERDELIPERSONS_FAIL,
            payload: err
        });
    }
};

//partner addresses get
export const getAllPartneraddressesAction = (formData) => async dispatch => {
    try {
        dispatch({
            type: PARTNER_ADDRESS_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Partners/Partner_Address_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=PARTNER_ADDRESS_ID&SortOrder=ASC`, formData);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: PARTNER_ADDRESS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: PARTNER_ADDRESS_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: PARTNER_ADDRESS_FAIL,
            payload: err
        });
    }
};

export const getSingleOrderAction = (formData, callBackorderData) => async dispatch => {
    try {
        let { data } = await nodeGetApi.post(
            `/Super_Admin/Order_GetFlow?PageNo=1&PageSize=${config.pageSize}&SortBy=Order_Id&SortOrder=ASC`, formData);
        if (data) {
            callBackorderData(data)
        }
    } catch (err) {
    }
};


//Get ALL Order Address Action
export const getAllOrderAddressAction = (formData) => async (dispatch) => {
    try {
        dispatch({
            type: ORDER_ADDRESS_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Order_Management/GET?PageNo=1&PageSize=${config.pageSize}&SortBy=ORDER_ID&SortOrder=ASC`, formData);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: ORDER_ADDRESS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: ORDER_ADDRESS_SUCCESS,
                    payload: data?.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: ORDER_ADDRESS_FAIL,
            payload: err
        });
    }
};


//Get ALL Order line item Address Action
export const getAllOrderItemAddressAction = (formData) => async (dispatch) => {
    try {
        dispatch({
            type: ORDER_ITEM_ADDRESS_REQUEST
        });
        let { data } = await nodeGetApi.post(`/Order_Management/GET?PageNo=1&PageSize=${config.pageSize}&SortBy=ORDER_ID&SortOrder=ASC`, formData);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: ORDER_ITEM_ADDRESS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: ORDER_ITEM_ADDRESS_SUCCESS,
                    payload: data?.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: ORDER_ITEM_ADDRESS_FAIL,
            payload: err
        });
    }
};

//Payment Authorization
export const paymentAuthorizationAction = (formData, ID, navigate) => async dispatch => {
    dispatch({
        type: ORDER_PAYMENT_AUTH_REQUEST
    });
    boomiAdminApi.post(`/Razorpay/Payment_Status_Update_Capture`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.status === "captured") {
                dispatch({
                    type: ORDER_PAYMENT_AUTH_SUCCESS
                });
                toast('Payment Authorized successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                if (ID === '1') {
                    navigate('/app/order-history')
                } else {
                    navigate('/app/shipping')
                }
            } else {
                toast(successResponse.error.description, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                dispatch({
                    type: ORDER_PAYMENT_AUTH_FAIL,
                    payload: successResponse.error.description
                });
            }
        })
        .catch(error => {
            dispatch({
                type: ORDER_PAYMENT_AUTH_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};

//Partner Payment
export const OrderPartnerPaymentAction = (formData, ID, navigate) => async dispatch => {
    dispatch({
        type: ORDER_PARTNER_PAYMENT_REQUEST
    });
    boomiAdminApi.post(`/Razorpay/Transaction_Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: ORDER_PARTNER_PAYMENT_SUCCESS
                });
                toast('Partner Payment Processed successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                if (ID === '1') {
                    navigate('/app/order-history')
                } else {
                    navigate('/app/shipping')
                }
            } else {
                toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                dispatch({
                    type: ORDER_PARTNER_PAYMENT_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: ORDER_PARTNER_PAYMENT_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};

// order ship update
export const updateOrderShipAction = (formData, ID, navigate) => async dispatch => {
    dispatch({
        type: ORDER_SHIP_REQUEST
    });
    boomiAdminApi.post(`/Ship_Rocket/Create_Order`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: ORDER_SHIP_SUCCESS
                });
                toast('Shipment Processed successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                if (ID === '1') {
                    navigate('/app/order-history')
                } else {
                    navigate('/app/shipping')
                }
            } else {
                toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                dispatch({
                    type: ORDER_SHIP_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: ORDER_SHIP_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};


// order shipment cancel
export const OrderCancelShipAction = (formData, ID, navigate) => async dispatch => {
    dispatch({
        type: ORDER_SHIP_CANCEL_REQUEST
    });
    boomiAdminApi.put(`/Ship_Rocket/Cancel_Order`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: ORDER_SHIP_CANCEL_SUCCESS
                });
                toast('Shipment Cancelled successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                if (ID === '1') {
                    navigate('/app/order-history')
                } else {
                    navigate('/app/shipping')
                }
            } else {
                toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                dispatch({
                    type: ORDER_SHIP_CANCEL_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: ORDER_SHIP_CANCEL_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};

// order ship nimbus create
export const createOrderShipAction = (formData, ID, navigate) => async dispatch => {
    dispatch({
        type: ORDER_SHIP_NIMBUS_REQUEST
    });
    boomiAdminApi.post(`/NimbusPost/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: ORDER_SHIP_NIMBUS_SUCCESS
                });
                toast('Shipment Processed successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                if (ID === '1') {
                    navigate('/app/order-history')
                } else {
                    navigate('/app/shipping')
                }
            } else {
                toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                dispatch({
                    type: ORDER_SHIP_NIMBUS_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: ORDER_SHIP_NIMBUS_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};


// order shipment nimbus cancel
export const cancelOrderShipAction = (formData, ID, navigate) => async dispatch => {
    dispatch({
        type: ORDER_SHIP_NIMBUS_CANCEL_REQUEST
    });
    boomiAdminApi.put(`/NimbusPost/Cancel`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {
                dispatch({
                    type: ORDER_SHIP_NIMBUS_CANCEL_SUCCESS
                });
                toast('Shipment Cancelled successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                if (ID === '1') {
                    navigate('/app/order-history')
                } else {
                    navigate('/app/shipping')
                }
            } else {
                toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                dispatch({
                    type: ORDER_SHIP_NIMBUS_CANCEL_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: ORDER_SHIP_NIMBUS_CANCEL_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};

export const invoiceget = (
    formData = {},
    updateStates
) => async (dispatch) => {
    nodeGetApi.post('/Order/Invoice_Get',
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            updateStates(true, []);
        } else {
            try {
                updateStates(true, data?.results[0]);
            } catch (error) {
                updateStates(true, data.results);
            }
        }
    }).catch(error => {
        updateStates(false, error);
    });
};