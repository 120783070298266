import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  updateInventoryAction,
  getAllPartners,
  getAllProducts,
  getAllMasters,
  getAllSizes,
  getAllDetails
} from './../../store/actions/inventoryAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from "focus-formik-error";
import { CircularProgress } from '@mui/material';

const statusOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const EditInventory = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = location;

  /// componentDidMount
  useEffect(() => {
    let formData = {
      Records_Filter: "FILTER",
      Partner_Product_Id: state.Partner_Product_Id
    };
    let formData1 = {
      Records_Filter: "FILTER",
      Product_Master_Id: state.Product_Master_Id,
      Partner_Details_Id: state.Partner_Details_Id
    };
    dispatch(getAllPartners(formData));
    dispatch(getAllProducts());
    dispatch(getAllDetails());
    dispatch(getAllMasters());
    dispatch(getAllSizes(formData1));
  }, []);

  const isSubmited = useSelector(state => state.inventory.inventoryUpdate.isLoading);
  const errorMessage = useSelector(state => state.inventory.inventoryUpdate.error);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const partners = useSelector(state => state.inventory.inventoryPartners.partners);
  const sizes = useSelector(state => state.inventory.inventorySizes.sizes);

  return (
    <Formik
      initialValues={{
        Product_Id: state.Product_Id ? state.Product_Id : "",
        Inventory_Id: state.Inventory_Id ? state.Inventory_Id : "",
        Partner_Product_Id: state.Partner_Product_Id ? state.Partner_Product_Id : "",
        Partner_Detail_Id: state.Partner_Details_Id ? state.Partner_Details_Id : "",
        Product_Master_Id: state.Product_Master_Id ? state.Product_Master_Id : "",
        Product_Size: state.Product_Size ? state.Product_Size : "",
        Inventory_Version: state.Inventory_Version ? state.Inventory_Version : "",
        Availability_Status: state.Availability_Status ? state.Availability_Status : "",
        Availability_Stock: state.Availability_Stock ? state.Availability_Stock : "",
        Available_Date: state.Available_Date ? state.Available_Date : "",
        Total_Stock: state.Total_Stock ? state.Total_Stock : "",
        Modified_By: loginEmail,
      }}
      validationSchema={
        Yup.object().shape({
          Inventory_Version: Yup.string().required('Inventory Version is required.'),
          Available_Date: Yup.date().min(Date(), "Date must not be past date.").required('Available Date is required.'),
          Total_Stock: Yup.number().positive('Total Stock must be a positive number.')
            .min(Yup.ref('Availability_Stock'), 'Total Stock must be greater than or equal to Availability Stock')
            .required('Total Stock is required.'),
          Availability_Status: Yup.string().required('Availability Status is required.'),
          Availability_Stock: Yup.number().min(0, "Availability Stock should not be less than 0.").required('Availability Stock is required.'),
        })
      }
      onSubmit={(values) => {
        let formData = JSON.parse(JSON.stringify(values));
        dispatch(updateInventoryAction(formData, navigate));
      }
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    {/* Partner Product Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled
                        label="Partner with Product Name"
                        name="Partner_Product_Id"
                        value={values.Partner_Product_Id}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        <option key="" value="">--Please Select--</option>
                        {partners.map((option, i) => (
                          <option key={i} value={option.Partner_Product_Id}>
                            {option.Partner_Name}-{option.Product_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/*Product_Master_Id*/}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        disabled
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Product Master Name"
                        name="Product_Master_Id"
                        value={values.Product_Master_Id}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        {partners.map((option, i) => (
                          <option key={i} value={option.Product_Master_Id}>{option.Product_Master_Name}</option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Product Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        disabled
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Product Name"
                        name="Product_Id"
                        value={values.Product_Id}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        <option key="" value="">--Please Select--</option>
                        {partners.map((option, i) => (
                          <option key={i} value={option.Product_Id}>
                            {option.Product_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Partner Detail Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        disabled
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Partner Name"
                        name="Partner_Detail_Id"
                        value={values.Partner_Detail_Id}
                        variant="outlined"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                      >
                        <option key="" value="">--Please Select--</option>
                        {partners.map((option, i) => (
                          <option key={i} value={option.Partner_Details_Id}>
                            {option.Partner_Name}</option>
                        ))}

                      </TextField>
                    </Grid>
                    {/* Product_Size */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Product_Size && errors.Product_Size)}
                        fullWidth
                        helperText={touched.Product_Size && errors.Product_Size}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Product Size"
                        name="Product_Size"
                        InputLabelProps={{ shrink: true }}
                        value={values.Product_Size}
                        variant="outlined"
                        select
                        SelectProps={{ native: true }}
                      >
                        <option key="" value="">--Please Select--</option>
                        {sizes.map((option, i) => (
                          <option key={i} value={option.Size} >{option.Size + " - " + option.Size_Measurement}</option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Inventory Version */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Inventory_Version && errors.Inventory_Version)}
                        fullWidth
                        helperText={touched.Inventory_Version && errors.Inventory_Version}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Inventory Version"
                        name="Inventory_Version"
                        value={values.Inventory_Version}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>
                    {/* Total Stock */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Total_Stock && errors.Total_Stock)}
                        fullWidth
                        helperText={touched.Total_Stock && errors.Total_Stock}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        label="Total Stock"
                        name="Total_Stock"
                        value={values.Total_Stock}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>
                    {/* Availability Stock */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Availability_Stock && errors.Availability_Stock)}
                        fullWidth
                        helperText={touched.Availability_Stock && errors.Availability_Stock}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        label="Availability Stock"
                        name="Availability_Stock"
                        value={values.Availability_Stock}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>
                    {/* Available Date */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Available_Date && errors.Available_Date)}
                        fullWidth
                        helperText={touched.Available_Date && errors.Available_Date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="datetime-local"
                        label="Available Date"
                        name="Available_Date"
                        value={values.Available_Date}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                      </TextField>
                    </Grid>
                    {/* Availability Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Availability_Status && errors.Availability_Status)}
                        fullWidth
                        helperText={touched.Availability_Status && errors.Availability_Status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Availability Status"
                        name="Availability_Status"
                        select
                        SelectProps={{ native: true }}
                        value={values.Availability_Status}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditInventory.propTypes = {
  className: PropTypes.string
};

export default EditInventory;