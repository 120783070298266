export default [
    {
        value: '--Please Select--',
        label: '--Please Select--'
    },
    //Left Nav Modules
    {
        value: 'Reports_Hide',
        label: 'Reports_Hide'
    },
    {
        value: 'Company_Settings_Hide',
        label: 'Company_Settings_Hide'
    },
    {
        value: 'ESI_Main_Center_Hide',
        label: 'ESI_Main_Center_Hide'
    },
    {
        value: 'Partner_Management_Hide',
        label: 'Partner_Management_Hide'
    },
    {
        value: 'Users_Management_Hide',
        label: 'Users_Management_Hide'
    },
    {
        value: 'Order_Management_Hide',
        label: 'Order_Management_Hide'
    },
    {
        value: 'Promotion_Management_Hide',
        label: 'Promotion_Management_Hide'
    },
    {
        value: 'Content_Management_Main_Hide',
        label: 'Content_Management_Main_Hide'
    },
    {
        value: 'Delivery_Management_Hide',
        label: 'Delivery_Management_Hide'
    },
    {
        value: 'Corporate_Management_Hide',
        label: 'Corporate_Management_Hide'
    },
    {
        value: 'Store_Type_Management_Main_Hide',
        label: 'Store_Type_Management_Main_Hide'
    },
    {
        value: 'User_Ride_Management_Hide',
        label: 'User_Ride_Management_Hide'
    },
    {
        value: 'Services_Hide',
        label: 'Services_Hide'
    },
    {
        value: 'Bidding_Management_Hide',
        label: 'Bidding_Management_Hide'
    },
    {
        value: 'Meepaisa_Fashion_Hub_Hide',
        label: 'Meepaisa_Fashion_Hub_Hide'
    },
    {
        value: 'Razorpay_Payments_Main_Hide',
        label: 'Razorpay_Payments_Main_Hide'
    },
    {
        value: 'Meepaisa_Global_Coupons_Hide',
        label: 'Meepaisa_Global_Coupons_Hide'
    },
    {
        value: 'Settings_Hide',
        label: 'Settings_Hide'
    },
    // Reports_Dasboard
    {
        value: 'Reports_Dashboard_Read',
        label: 'Reports_Dashboard_Read'
    },
    {
        value: 'Reports_Dashboard_Hide',
        label: 'Reports_Dashboard_Hide'
    },
    //Dasboard
    {
        value: 'Total_Cashback_Hide',
        label: 'Total_Cashback_Hide'
    },
    {
        value: 'Total_Online_Partners_Hide',
        label: 'Total_Online_Partners_Hide'
    },
    {
        value: 'Total_Orders_Hide',
        label: 'Total_Orders_Hide'
    },
    {
        value: 'Total_Partners_Hide',
        label: 'Total_Partners_Hide'
    },
    {
        value: 'Total_Physical_Partners_Hide',
        label: 'Total_Physical_Partners_Hide'
    },
    {
        value: 'Total_Brands_Hide',
        label: 'Total_Brands_Hide'
    },
    {
        value: 'Total_Coupons_Hide',
        label: 'Total_Coupons_Hide'
    },
    {
        value: 'Total_Users_Hide',
        label: 'Total_Users_Hide'
    },
    {
        value: 'Total_Deals_Hide',
        label: 'Total_Deals_Hide'
    },
    {
        value: 'Total_Products_Hide',
        label: 'Total_Products_Hide'
    },
    {
        value: 'Total_Profit_Hide',
        label: 'Total_Profit_Hide'
    },
    {
        value: 'Top_Coupons_Hide',
        label: 'Top_Coupons_Hide'
    },
    {
        value: 'Top_Deals_Hide',
        label: 'Top_Deals_Hide'
    },
    {
        value: 'Top_Products_Hide',
        label: 'Top_Products_Hide'
    },
    // Reports_Budget
    {
        value: 'Reports_Budget_Read',
        label: 'Reports_Budget_Read'
    },
    {
        value: 'Reports_Budget_Hide',
        label: 'Reports_Budget_Hide'
    },
    // Reports_Users
    {
        value: 'Reports_Users_Read',
        label: 'Reports_Users_Read'
    },
    {
        value: 'Reports_Users_Hide',
        label: 'Reports_Users_Hide'
    },
    // Reports_Partners
    {
        value: 'Reports_Partners_Read',
        label: 'Reports_Partners_Read'
    },
    {
        value: 'Reports_Partners_Hide',
        label: 'Reports_Partners_Hide'
    },
    // Reports_Brands
    {
        value: 'Reports_Brands_Read',
        label: 'Reports_Brands_Read'
    },
    {
        value: 'Reports_Brands_Hide',
        label: 'Reports_Brands_Hide'
    },
    // Reports_Coupons
    {
        value: 'Reports_Coupons_Read',
        label: 'Reports_Coupons_Read'
    },
    {
        value: 'Reports_Coupons_Hide',
        label: 'Reports_Coupons_Hide'
    },
    // Reports_Deals
    {
        value: 'Reports_Deals_Read',
        label: 'Reports_Deals_Read'
    },
    {
        value: 'Reports_Deals_Hide',
        label: 'Reports_Deals_Hide'
    },
    // Reports_Products
    {
        value: 'Reports_Products_Read',
        label: 'Reports_Products_Read'
    },
    {
        value: 'Reports_Products_Hide',
        label: 'Reports_Products_Hide'
    },
    // Role_Management
    {
        value: 'Role_Management_Read',
        label: 'Role_Management_Read'
    },
    {
        value: 'Role_Management_Write',
        label: 'Role_Management_Write'
    },
    {
        value: 'Role_Management_Hide',
        label: 'Role_Management_Hide'
    },
    // Company_Profile_Management
    {
        value: 'Company_Profile_Management_Read',
        label: 'Company_Profile_Management_Read'
    },
    {
        value: 'Company_Profile_Management_Write',
        label: 'Company_Profile_Management_Write'
    },
    {
        value: 'Company_Profile_Management_Hide',
        label: 'Company_Profile_Management_Hide'
    },
    // Admin_Employee_Management
    {
        value: 'Admin_Employee_Management_Read',
        label: 'Admin_Employee_Management_Read'
    },
    {
        value: 'Admin_Employee_Management_Write',
        label: 'Admin_Employee_Management_Write'
    },
    {
        value: 'Admin_Employee_Management_Hide',
        label: 'Admin_Employee_Management_Hide'
    },
    // Permissions_Management
    {
        value: 'Permissions_Management_Read',
        label: 'Permissions_Management_Read'
    },
    {
        value: 'Permissions_Management_Write',
        label: 'Permissions_Management_Write'
    },
    {
        value: 'Permissions_Management_Hide',
        label: 'Permissions_Management_Hide'
    },
    // Category_Management
    {
        value: 'Category_Management_Read',
        label: 'Category_Management_Read'
    },
    {
        value: 'Category_Management_Write',
        label: 'Category_Management_Write'
    },
    {
        value: 'Category_Management_Hide',
        label: 'Category_Management_Hide'
    },
    // SubCategory_Management
    {
        value: 'SubCategory_Management_Read',
        label: 'SubCategory_Management_Read'
    },
    {
        value: 'SubCategory_Management_Write',
        label: 'SubCategory_Management_Write'
    },
    {
        value: 'SubCategory_Management_Hide',
        label: 'SubCategory_Management_Hide'
    },
    // Brand_Management
    {
        value: 'Brand_Management_Read',
        label: 'Brand_Management_Read'
    },
    {
        value: 'Brand_Management_Write',
        label: 'Brand_Management_Write'
    },
    {
        value: 'Brand_Management_Hide',
        label: 'Brand_Management_Hide'
    },
    // Product_Management
    {
        value: 'Product_Management_Main_Hide',
        label: 'Product_Management_Main_Hide'
    },
    // Product_Master
    {
        value: 'Product_Master_Write',
        label: 'Product_Master_Write'
    },
    {
        value: 'Product_Master_Hide',
        label: 'Product_Master_Hide'
    },
    // Product_Sizes
    {
        value: 'Product_Sizes_Write',
        label: 'Product_Sizes_Write'
    },
    // Product_Colors
    {
        value: 'Product_Colors_Write',
        label: 'Product_Colors_Write'
    },
    // Product_Management
    {
        value: 'Product_Management_Read',
        label: 'Product_Management_Read'
    },
    {
        value: 'Product_Management_Write',
        label: 'Product_Management_Write'
    },
    {
        value: 'Product_Management_Hide',
        label: 'Product_Management_Hide'
    },
    // Pending_Products
    {
        value: 'Pending_Products_Read',
        label: 'Pending_Products_Read'
    },
    {
        value: 'Pending_Products_Write',
        label: 'Pending_Products_Write'
    },
    // Meepaisa_Product_Combo
    {
        value: 'Meepaisa_Product_Combo_Read',
        label: 'Meepaisa_Product_Combo_Read'
    },
    {
        value: 'Meepaisa_Product_Combo_Write',
        label: 'Meepaisa_Product_Combo_Write'
    },
    // Product_Partner
    {
        value: 'Product_Partner_Read',
        label: 'Product_Partner_Read'
    },
    {
        value: 'Product_Partner_Write',
        label: 'Product_Partner_Write'
    },
    // Pending_Product_Partner
    {
        value: 'Pending_Product_Partner_Write',
        label: 'Pending_Product_Partner_Write'
    },
    // Related_Products
    {
        value: 'Related_Products_Write',
        label: 'Related_Products_Write'
    },
    // Copy_Products
    {
        value: 'Copy_Products_Write',
        label: 'Copy_Products_Write'
    },
    // Copy_Product_Partner_Write
    {
        value: 'Copy_Product_Partner_Write',
        label: 'Copy_Product_Partner_Write'
    },
    // Inventory_Management
    {
        value: 'Inventory_Management_Read',
        label: 'Inventory_Management_Read'
    },
    {
        value: 'Inventory_Management_Write',
        label: 'Inventory_Management_Write'
    },
    {
        value: 'Inventory_Management_Hide',
        label: 'Inventory_Management_Hide'
    },
    // Coupon_Management
    {
        value: 'Coupon_Management_Read',
        label: 'Coupon_Management_Read'
    },
    {
        value: 'Coupon_Management_Write',
        label: 'Coupon_Management_Write'
    },
    {
        value: 'Coupon_Management_Hide',
        label: 'Coupon_Management_Hide'
    },
    // Pending_Coupons
    {
        value: 'Pending_Coupons_Read',
        label: 'Pending_Coupons_Read'
    },
    {
        value: 'Pending_Coupons_Write',
        label: 'Pending_Coupons_Write'
    },
    // Deal_Management
    {
        value: 'Deal_Management_Read',
        label: 'Deal_Management_Read'
    },
    {
        value: 'Deal_Management_Write',
        label: 'Deal_Management_Write'
    },
    {
        value: 'Deal_Management_Hide',
        label: 'Deal_Management_Hide'
    },
    // Pending_Deals
    {
        value: 'Pending_Deals_Read',
        label: 'Pending_Deals_Read'
    },
    {
        value: 'Pending_Deals_Write',
        label: 'Pending_Deals_Write'
    },
    // Shop_By_City_Management
    {
        value: 'Shop_By_City_Management_Hide',
        label: 'Shop_By_City_Management_Hide'
    },
    // City_Management
    {
        value: 'City_Management_Read',
        label: 'City_Management_Read'
    },
    {
        value: 'City_Management_Write',
        label: 'City_Management_Write'
    },
    {
        value: 'City_Management_Hide',
        label: 'City_Management_Hide'
    },
    // City_Locations
    {
        value: 'City_Locations_Read',
        label: 'City_Locations_Read'
    },
    {
        value: 'City_Locations_Write',
        label: 'City_Locations_Write'
    },
    // Cities_Partner_Management
    {
        value: 'Cities_Partner_Management_Read',
        label: 'Cities_Partner_Management_Read'
    },
    {
        value: 'Cities_Partner_Management_Write',
        label: 'Cities_Partner_Management_Write'
    },
    {
        value: 'Cities_Partner_Management_Hide',
        label: 'Cities_Partner_Management_Hide'
    },
    // Partner_Locations
    {
        value: 'Partner_Locations_Read',
        label: 'Partner_Locations_Read'
    },
    {
        value: 'Partner_Locations_Write',
        label: 'Partner_Locations_Write'
    },
    // City_Coupon_Management
    {
        value: 'City_Coupon_Management_Read',
        label: 'City_Coupon_Management_Read'
    },
    {
        value: 'City_Coupon_Management_Write',
        label: 'City_Coupon_Management_Write'
    },
    // City_Deal_Management
    {
        value: 'City_Deal_Management_Read',
        label: 'City_Deal_Management_Read'
    },
    {
        value: 'City_Deal_Management_Write',
        label: 'City_Deal_Management_Write'
    },
    //City_Product_Management
    {
        value: 'City_Product_Management_Read',
        label: 'City_Product_Management_Read'
    },
    {
        value: 'City_Product_Management_Write',
        label: 'City_Product_Management_Write'
    },
    //Banner_Management
    {
        value: 'Banner_Management_Write',
        label: 'Banner_Management_Write'
    },
    {
        value: 'Banner_Management_Hide',
        label: 'Banner_Management_Hide'
    },
    //Gift_Management
    {
        value: 'Gift_Management_Hide',
        label: 'Gift_Management_Hide'
    },
    //Gift_Vendors
    {
        value: 'Gift_Vendors_Read',
        label: 'Gift_Vendors_Read'
    },
    {
        value: 'Gift_Vendors_Write',
        label: 'Gift_Vendors_Write'
    },
    {
        value: 'Gift_Vendors_Hide',
        label: 'Gift_Vendors_Hide'
    },
    //Gift_Images
    {
        value: 'Gift_Images_Write',
        label: 'Gift_Images_Write'
    },
    {
        value: 'Gift_Images_Hide',
        label: 'Gift_Images_Hide'
    },
    // User_Gifts
    {
        value: 'User_Gifts_Read',
        label: 'User_Gifts_Read'
    },
    {
        value: 'User_Gifts_Write',
        label: 'User_Gifts_Write'
    },
    {
        value: 'User_Gifts_Hide',
        label: 'User_Gifts_Hide'
    },
    // User_Gift_History
    {
        value: 'User_Gift_History_Read',
        label: 'User_Gift_History_Read'
    },
    // Partner_Management
    {
        value: 'Partner_Management_Main_Write',
        label: 'Partner_Management_Main_Write'
    },
    {
        value: 'Partner_Management_Main_Hide',
        label: 'Partner_Management_Main_Hide'
    },
    // Pending_Partners
    {
        value: 'Pending_Partners_Read',
        label: 'Pending_Partners_Read'
    },
    {
        value: 'Pending_Partners_Write',
        label: 'Pending_Partners_Write'
    },
    // Partner_Address
    {
        value: 'Partner_Address_Read',
        label: 'Partner_Address_Read'
    },
    {
        value: 'Partner_Address_Write',
        label: 'Partner_Address_Write'
    },
    // Partner_Employees
    {
        value: 'Partner_Employees_Read',
        label: 'Partner_Employees_Read'
    },
    {
        value: 'Partner_Employees_Write',
        label: 'Partner_Employees_Write'
    },
    // Partner_Delivery_Agents
    {
        value: 'Partner_Delivery_Agents_Read',
        label: 'Partner_Delivery_Agents_Read'
    },
    {
        value: 'Partner_Delivery_Agents_Write',
        label: 'Partner_Delivery_Agents_Write'
    },
    // Partner_Vehicle_Details
    {
        value: 'Partner_Vehicle_Details_Read',
        label: 'Partner_Vehicle_Details_Read'
    },
    {
        value: 'Partner_Vehicle_Details_Write',
        label: 'Partner_Vehicle_Details_Write'
    },
    // Partner_Bank_Details
    {
        value: 'Partner_Bank_Details_Read',
        label: 'Partner_Bank_Details_Read'
    },
    // Partner_Ticketing_Management
    {
        value: 'Partner_Ticketing_Management_Read',
        label: 'Partner_Ticketing_Management_Read'
    },
    {
        value: 'Partner_Ticketing_Management_Write',
        label: 'Partner_Ticketing_Management_Write'
    },
    {
        value: 'Partner_Ticketing_Management_Hide',
        label: 'Partner_Ticketing_Management_Hide'
    },
    // Partner_Comments
    {
        value: 'Partner_Comments_Read',
        label: 'Partner_Comments_Read'
    },
    {
        value: 'Partner_Comments_Write',
        label: 'Partner_Comments_Write'
    },
    // Partner_Wallet
    {
        value: 'Partner_Wallet_Management_Read',
        label: 'Partner_Wallet_Management_Read'
    },
    {
        value: 'Partner_Wallet_Management_Write',
        label: 'Partner_Wallet_Management_Write'
    },
    {
        value: 'Partner_Wallet_Management_Hide',
        label: 'Partner_Wallet_Management_Hide'
    },
    // Partner_Wallet_History
    {
        value: 'Partner_Wallet_History_Read',
        label: 'Partner_Wallet_History_Read'
    },
    {
        value: 'Partner_Wallet_History_Write',
        label: 'Partner_Wallet_History_Write'
    },
    // Partner_Services_Management
    {
        value: 'Partner_Services_Management_Read',
        label: 'Partner_Services_Management_Read'
    },
    {
        value: 'Partner_Services_Management_Write',
        label: 'Partner_Services_Management_Write'
    },
    {
        value: 'Partner_Services_Management_Hide',
        label: 'Partner_Services_Management_Hide'
    },

    // User_Management
    {
        value: 'User_Management_Read',
        label: 'User_Management_Read'
    },
    {
        value: 'User_Management_Write',
        label: 'User_Management_Write'
    },
    {
        value: 'User_Management_Hide',
        label: 'User_Management_Hide'
    },
    // User_Profile_Management
    {
        value: 'User_Profile_Management_Read',
        label: 'User_Profile_Management_Read'
    },
    {
        value: 'User_Profile_Management_Write',
        label: 'User_Profile_Management_Write'
    },
    {
        value: 'User_Profile_Management_Hide',
        label: 'User_Profile_Management_Hide'
    },
    // Missing_Cashback
    {
        value: 'Missing_Cashback_Read',
        label: 'Missing_Cashback_Read'
    },
    {
        value: 'Missing_Cashback_Write',
        label: 'Missing_Cashback_Write'
    },
    {
        value: 'Missing_Cashback_Hide',
        label: 'Missing_Cashback_Hide'
    },
    // User_Tickets
    {
        value: 'User_Ticketing_Management_Read',
        label: 'User_Ticketing_Management_Read'
    },
    {
        value: 'User_Ticketing_Management_Write',
        label: 'User_Ticketing_Management_Write'
    },
    {
        value: 'User_Ticketing_Management_Hide',
        label: 'User_Ticketing_Management_Hide'
    },
    // User_Comments
    {
        value: 'User_Comments_Read',
        label: 'User_Comments_Read'
    },
    {
        value: 'User_Comments_Write',
        label: 'User_Comments_Write'
    },
    // User_Wallet
    {
        value: 'User_Wallet_Read',
        label: 'User_Wallet_Read'
    },
    {
        value: 'User_Wallet_Write',
        label: 'User_Wallet_Write'
    },
    {
        value: 'User_Wallet_Hide',
        label: 'User_Wallet_Hide'
    },
    // User_Wallet_History
    {
        value: 'User_Wallet_History_Read',
        label: 'User_Wallet_History_Read'
    },
    {
        value: 'User_Wallet_History_Write',
        label: 'User_Wallet_History_Write'
    },
    // User_Financial_Transactions
    {
        value: 'User_Financial_Transactions_Read',
        label: 'User_Financial_Transactions_Read'
    },
    {
        value: 'User_Financial_Transactions_Write',
        label: 'User_Financial_Transactions_Write'
    },
    {
        value: 'User_Financial_Transactions_Hide',
        label: 'User_Financial_Transactions_Hide'
    },

    // Order_History
    {
        value: 'Order_History_Write',
        label: 'Order_History_Write'
    },
    {
        value: 'Order_History_Hide',
        label: 'Order_History_Hide'
    },
    // B2b_Order_History
    {
        value: 'B2b_Order_History_Write',
        label: 'B2b_Order_History_Write'
    },
    {
        value: 'B2b_Order_History_Hide',
        label: 'B2b_Order_History_Hide'
    },
    // Reviews_Ratings
    {
        value: 'Reviews_Ratings_Read',
        label: 'Reviews_Ratings_Read'
    },
    {
        value: 'Reviews_Ratings_Hide',
        label: 'Reviews_Ratings_Hide'
    },
    // Product_Reviews_Ratings
    {
        value: 'Product_Reviews_Ratings_Write',
        label: 'Product_Reviews_Ratings_Write'
    },
    // Partner_Reviews_Ratings
    {
        value: 'Partner_Reviews_Ratings_Write',
        label: 'Partner_Reviews_Ratings_Write'
    },
    // Brand_Reviews_Ratings
    {
        value: 'Brand_Reviews_Ratings_Write',
        label: 'Brand_Reviews_Ratings_Write'
    },
    // Transaction_History
    {
        value: 'Transaction_History_Read',
        label: 'Transaction_History_Read'
    },
    {
        value: 'Transaction_History_Write',
        label: 'Transaction_History_Write'
    },
    {
        value: 'Transaction_History_Hide',
        label: 'Transaction_History_Hide'
    },
    // Payments_Settlements
    {
        value: 'Payments_Settlements_Write',
        label: 'Payments_Settlements_Write'
    },
    {
        value: 'Payments_Settlements_Hide',
        label: 'Payments_Settlements_Hide'
    },
    // Partner_Payments_Settlements
    {
        value: 'Partner_Payments_Settlements_Read',
        label: 'Partner_Payments_Settlements_Read'
    },
    {
        value: 'Partner_Payments_Settlements_Write',
        label: 'Partner_Payments_Settlements_Write'
    },
    {
        value: 'Partner_Payments_Settlements_Hide',
        label: 'Partner_Payments_Settlements_Hide'
    },
    // User_Payments_Settlements
    {
        value: 'User_Payments_Settlements_Read',
        label: 'User_Payments_Settlements_Read'
    },
    {
        value: 'User_Payments_Settlements_Write',
        label: 'User_Payments_Settlements_Write'
    },
    {
        value: 'User_Payments_Settlements_Hide',
        label: 'User_Payments_Settlements_Hide'
    },
    // Order_Shipping
    {
        value: 'Order_Shipping_Write',
        label: 'Order_Shipping_Write'
    },
    {
        value: 'Order_Shipping_Hide',
        label: 'Order_Shipping_Hide'
    },
    // Offer_Visitors
    {
        value: 'Offer_Visitors_Write',
        label: 'Offer_Visitors_Write'
    },
    {
        value: 'Offer_Visitors_Hide',
        label: 'Offer_Visitors_Hide'
    },
    // Refer_Earn
    {
        value: 'Refer_Earn_Read',
        label: 'Refer_Earn_Read'
    },
    {
        value: 'Refer_Earn_Write',
        label: 'Refer_Earn_Write'
    },
    {
        value: 'Refer_Earn_Hide',
        label: 'Refer_Earn_Hide'
    },
    // News_Letter
    {
        value: 'Newsletter_Write',
        label: 'Newsletter_Write'
    },
    {
        value: 'Newsletter_Hide',
        label: 'Newsletter_Hide'
    },
    // Ads_Management
    {
        value: 'Ads_Management_Write',
        label: 'Ads_Management_Write'
    },
    {
        value: 'Ads_Management_Hide',
        label: 'Ads_Management_Hide'
    },
    // Promotional_Management
    {
        value: 'Promotional_Management_Write',
        label: 'Promotional_Management_Write'
    },
    {
        value: 'Promotional_Management_Hide',
        label: 'Promotional_Management_Hide'
    },
    // Popup_Management
    {
        value: 'Popup_Management_Write',
        label: 'Popup_Management_Write'
    },
    {
        value: 'Popup_Management_Hide',
        label: 'Popup_Management_Hide'
    },

    // News_Events
    {
        value: 'News_Events_Write',
        label: 'News_Events_Write'
    },
    {
        value: 'News_Events_Hide',
        label: 'News_Events_Hide'
    },
    // FAQ_Management
    {
        value: 'FAQ_Management_Write',
        label: 'FAQ_Management_Write'
    },
    {
        value: 'FAQ_Management_Hide',
        label: 'FAQ_Management_Hide'
    },
    // Content_Management
    {
        value: 'Content_Management_Read',
        label: 'Content_Management_Read'
    },
    {
        value: 'Content_Management_Write',
        label: 'Content_Management_Write'
    },
    {
        value: 'Content_Management_Hide',
        label: 'Content_Management_Hide'
    },
    // Testimonial_Management
    {
        value: 'Testimonial_Management_Read',
        label: 'Testimonial_Management_Read'
    },
    {
        value: 'Testimonial_Management_Write',
        label: 'Testimonial_Management_Write'
    },
    {
        value: 'Testimonial_Management_Hide',
        label: 'Testimonial_Management_Hide'
    },
    // Delivery_Dashboard
    {
        value: 'Delivery_Dashboard_Read',
        label: 'Delivery_Dashboard_Read'
    },
    {
        value: 'Delivery_Dashboard_Hide',
        label: 'Delivery_Dashboard_Hide'
    },
    // Delivery_Dashboard
    {
        value: 'Delivery_Total_Orders_Hide',
        label: 'Delivery_Total_Orders_Hide'
    },
    {
        value: 'Delivery_Delivered_Orders_Hide',
        label: 'Delivery_Delivered_Orders_Hide'
    },
    {
        value: 'Delivery_OutforDelivery_Orders_Hide',
        label: 'Delivery_OutforDelivery_Orders_Hide'
    },
    {
        value: 'Delivery_Return_Orders_Hide',
        label: 'Delivery_Return_Orders_Hide'
    },
    {
        value: 'Delivery_Cancelled_Orders_Hide',
        label: 'Delivery_Cancelled_Orders_Hide'
    },
    {
        value: 'Top_Delivery_Persons_Hide',
        label: 'Top_Delivery_Persons_Hide'
    },
    // Delivery_Reports
    {
        value: 'Delivery_Reports_Read',
        label: 'Delivery_Reports_Read'
    },
    {
        value: 'Delivery_Reports_Hide',
        label: 'Delivery_Reports_Hide'
    },
    // Delivery_Reports_TotalOrders
    {
        value: 'Delivery_Reports_TotalOrders_Read',
        label: 'Delivery_Reports_TotalOrders_Read'
    },
    {
        value: 'Delivery_Reports_TotalOrders_Hide',
        label: 'Delivery_Reports_TotalOrders_Hide'
    },
    // Delivery_Reports_TotalOrders
    {
        value: 'Delivery_Reports_DeliveredOrders_Read',
        label: 'Delivery_Reports_DeliveredOrders_Read'
    },
    {
        value: 'Delivery_Reports_DeliveredOrders_Hide',
        label: 'Delivery_Reports_DeliveredOrders_Hide'
    },
    // Delivery_Reports_OutforDeliveryOrders
    {
        value: 'Delivery_Reports_OutforDeliveryOrders_Read',
        label: 'Delivery_Reports_OutforDeliveryOrders_Read'
    },
    {
        value: 'Delivery_Reports_OutforDeliveryOrders_Hide',
        label: 'Delivery_Reports_OutforDeliveryOrders_Hide'
    },
    // Delivery_Reports_ReturnedOrders
    {
        value: 'Delivery_Reports_ReturnedOrders_Read',
        label: 'Delivery_Reports_ReturnedOrders_Read'
    },
    {
        value: 'Delivery_Reports_ReturnedOrders_Hide',
        label: 'Delivery_Reports_ReturnedOrders_Hide'
    },
    // Delivery_Reports_CancelledOrders
    {
        value: 'Delivery_Reports_CancelledOrders_Read',
        label: 'Delivery_Reports_CancelledOrders_Read'
    },
    {
        value: 'Delivery_Reports_CancelledOrders_Hide',
        label: 'Delivery_Reports_CancelledOrders_Hide'
    },
    // Delivery_FAQS
    {
        value: 'Delivery_FAQ_Write',
        label: 'Delivery_FAQ_Write'
    },
    {
        value: 'Delivery_FAQ_Hide',
        label: 'Delivery_FAQ_Hide'
    },
    // Delivery_Content_Management
    {
        value: 'Delivery_Content_Management_Write',
        label: 'Delivery_Content_Management_Write'
    },
    {
        value: 'Delivery_Content_Management_Hide',
        label: 'Delivery_Content_Management_Hide'
    },
    // Approved_Delivery_Agents
    {
        value: 'Approved_Delivery_Agents_Read',
        label: 'Approved_Delivery_Agents_Read'
    },
    {
        value: 'Approved_Delivery_Agents_Write',
        label: 'Approved_Delivery_Agents_Write'
    },
    {
        value: 'Approved_Delivery_Agents_Hide',
        label: 'Approved_Delivery_Agents_Hide'
    },
    // Delivery_Vehicle_Details
    {
        value: 'Delivery_Vehicle_Details_Read',
        label: 'Delivery_Vehicle_Details_Read'
    },
    {
        value: 'Delivery_Vehicle_Details_Write',
        label: 'Delivery_Vehicle_Details_Write'
    },
    // Pending_Delivery_Agents
    {
        value: 'Pending_Delivery_Agents_Read',
        label: 'Pending_Delivery_Agents_Read'
    },
    {
        value: 'Pending_Delivery_Agents_Write',
        label: 'Pending_Delivery_Agents_Write'
    },
    {
        value: 'Pending_Delivery_Agents_Hide',
        label: 'Pending_Delivery_Agents_Hide'
    },
    // Delivery_Payments_Settlements
    {
        value: 'Delivery_Payments_Settlements_Read',
        label: 'Delivery_Payments_Settlements_Read'
    },
    {
        value: 'Delivery_Payments_Settlements_Write',
        label: 'Delivery_Payments_Settlements_Write'
    },
    {
        value: 'Delivery_Payments_Settlements_Hide',
        label: 'Delivery_Payments_Settlements_Hide'
    },
    // Delivery_Tickets
    {
        value: 'Delivery_Tickets_Read',
        label: 'Delivery_Tickets_Read'
    },
    {
        value: 'Delivery_Tickets_Write',
        label: 'Delivery_Tickets_Write'
    },
    {
        value: 'Delivery_Tickets_Hide',
        label: 'Delivery_Tickets_Hide'
    },
    // Delivery_Comments
    {
        value: 'Delivery_Comments_Read',
        label: 'Delivery_Comments_Read'
    },
    {
        value: 'Delivery_Comments_Write',
        label: 'Delivery_Comments_Write'
    },
    // Delivery_Wallet
    {
        value: 'Delivery_Wallet_Read',
        label: 'Delivery_Wallet_Read'
    },
    {
        value: 'Delivery_Wallet_Write',
        label: 'Delivery_Wallet_Write'
    },
    {
        value: 'Delivery_Wallet_Hide',
        label: 'Delivery_Wallet_Hide'
    },
    // Delivery_Wallet_History
    {
        value: 'Delivery_Wallet_History_Read',
        label: 'Delivery_Wallet_History_Read'
    },
    {
        value: 'Delivery_Wallet_History_Write',
        label: 'Delivery_Wallet_History_Write'
    },
    // Corporate_FAQS
    {
        value: 'Corporate_FAQ_Write',
        label: 'Corporate_FAQ_Write'
    },
    {
        value: 'Corporate_FAQ_Hide',
        label: 'Corporate_FAQ_Hide'
    },
    // Corporate_Content_Management
    {
        value: 'Corporate_Content_Management_Write',
        label: 'Corporate_Content_Management_Write'
    },
    {
        value: 'Corporate_Content_Management_Hide',
        label: 'Corporate_Content_Management_Hide'
    },
    // Store_Type_Management
    {
        value: 'Store_Type_Management_Read',
        label: 'Store_Type_Management_Read'
    },
    {
        value: 'Store_Type_Management_Write',
        label: 'Store_Type_Management_Write'
    },
    {
        value: 'Store_Type_Management_Hide',
        label: 'Store_Type_Management_Hide'
    },
    // Store_Type_Categories
    {
        value: 'Store_Type_Categories_Read',
        label: 'Store_Type_Categories_Read'
    },
    {
        value: 'Store_Type_Categories_Write',
        label: 'Store_Type_Categories_Write'
    },
    // Store_Type_Partners
    {
        value: 'Store_Type_Partners_Read',
        label: 'Store_Type_Partners_Read'
    },
    {
        value: 'Store_Type_Partners_Write',
        label: 'Store_Type_Partners_Write'
    },
    // User_Rides
    {
        value: 'User_Rides_Read',
        label: 'User_Rides_Read'
    },
    {
        value: 'User_Rides_Write',
        label: 'User_Rides_Write'
    },
    {
        value: 'User_Rides_Hide',
        label: 'User_Rides_Hide'
    },
    // User_Ride_Fares
    {
        value: 'User_Ride_Fares_Read',
        label: 'User_Ride_Fares_Read'
    },
    {
        value: 'User_Ride_Fares_Write',
        label: 'User_Ride_Fares_Write'
    },
    {
        value: 'User_Ride_Fares_Hide',
        label: 'User_Ride_Fares_Hide'
    },
    // Ride_Vehicle_Types
    {
        value: 'Ride_Vehicle_Types_Read',
        label: 'Ride_Vehicle_Types_Read'
    },
    {
        value: 'Ride_Vehicle_Types_Write',
        label: 'Ride_Vehicle_Types_Write'
    },
    {
        value: 'Ride_Vehicle_Types_Hide',
        label: 'Ride_Vehicle_Types_Hide'
    },
    //User_Services_Management
    {
        value: 'User_Services_Management_Read',
        label: 'User_Services_Management_Read'
    },
    {
        value: 'User_Services_Management_Write',
        label: 'User_Services_Management_Write'
    },
    {
        value: 'User_Services_Management_Hide',
        label: 'User_Services_Management_Hide'
    },
    //Services_Generic_Management
    {
        value: 'Services_Generic_Management_Read',
        label: 'Services_Generic_Management_Read'
    },
    {
        value: 'Services_Generic_Management_Write',
        label: 'Services_Generic_Management_Write'
    },
    {
        value: 'Services_Generic_Management_Hide',
        label: 'Services_Generic_Management_Hide'
    },
    //Bussiness_Services_Subtypes
    {
        value: 'Bussiness_Services_Subtypes_Read',
        label: 'Bussiness_Services_Subtypes_Read'
    },
    {
        value: 'Bussiness_Services_Subtypes_Write',
        label: 'Bussiness_Services_Subtypes_Write'
    },
    {
        value: 'Bussiness_Services_Subtypes_Hide',
        label: 'Bussiness_Services_Subtypes_Hide'
    },
    // Bidding_Management_Products
    {
        value: 'Bidding_Products_Hide',
        label: 'Bidding_Products_Hide'
    },
    // Bidding_Management_Services
    {
        value: 'Bidding_Services_Main_Hide',
        label: 'Bidding_Services_Main_Hide'
    },
    // Bidding_Rides
    {
        value: 'Bidding_Rides_Hide',
        label: 'Bidding_Rides_Hide'
    },
    // B2B_Bidding_Products_Hide
    {
        value: 'B2B_Bidding_Products_Hide',
        label: 'B2B_Bidding_Products_Hide'
    },// Bidding products category
    {
        value: 'Bidding_Products_Category_Read',
        label: 'Bidding_Products_Category_Read'
    },
    {
        value: 'Bidding_Products_Category_Write',
        label: 'Bidding_Products_Category_Write'
    },
    {
        value: 'Bidding_Products_Category_Hide',
        label: 'Bidding_Products_Category_Hide'
    },
    // Bidding products
    {
        value: 'Bidding_Products_Main_Read',
        label: 'Bidding_Products_Main_Read'
    },
    {
        value: 'Bidding_Products_Main_Write',
        label: 'Bidding_Products_Main_Write'
    },
    {
        value: 'Bidding_Products_Main_Hide',
        label: 'Bidding_Products_Main_Hide'
    },
    //Products Bidding Success Stories
    {
        value: 'Products_Bidding_Success_Story_Read',
        label: 'Products_Bidding_Success_Story_Read'
    },
    {
        value: 'Products_Bidding_Success_Story_Write',
        label: 'Products_Bidding_Success_Story_Write'
    },
    {
        value: 'Products_Bidding_Success_Story_Hide',
        label: 'Products_Bidding_Success_Story_Hide'
    },
    // Bidding User Products
    {
        value: 'Bidding_User_Products_Read',
        label: 'Bidding_User_Products_Read'
    },
    {
        value: 'Bidding_User_Products_Write',
        label: 'Bidding_User_Products_Write'
    },
    {
        value: 'Bidding_User_Products_Hide',
        label: 'Bidding_User_Products_Hide'
    },
    // Partner_Bidding_Available_Products
    {
        value: 'Partner_Bidding_Available_Products_Read',
        label: 'Partner_Bidding_Available_Products_Read'
    },
    {
        value: 'Partner_Bidding_Available_Products_Write',
        label: 'Partner_Bidding_Available_Products_Write'
    },
    {
        value: 'Partner_Bidding_Available_Products_Hide',
        label: 'Partner_Bidding_Available_Products_Hide'
    },
    // Partner_Bidding_Quotes
    {
        value: 'Partner_Bidding_Quotes_Read',
        label: 'Partner_Bidding_Quotes_Read'
    },
    {
        value: 'Partner_Bidding_Quotes_Write',
        label: 'Partner_Bidding_Quotes_Write'
    },
    // Partner_Bidding_Comments
    {
        value: 'Partner_Bidding_Comments_Write',
        label: 'Partner_Bidding_Comments_Write'
    },
    // Bidding Services
    {
        value: 'Bidding_Services_Read',
        label: 'Bidding_Services_Read'
    },
    {
        value: 'Bidding_Services_Write',
        label: 'Bidding_Services_Write'
    },
    {
        value: 'Bidding_Services_Hide',
        label: 'Bidding_Services_Hide'
    },
    // Bidding Partner Avail Services
    {
        value: 'Partner_Bidding_Avail_Services_Read',
        label: 'Partner_Bidding_Avail_Services_Read'
    },
    {
        value: 'Partner_Bidding_Avail_Services_Write',
        label: 'Partner_Bidding_Avail_Services_Write'
    },
    {
        value: 'Partner_Bidding_Avail_Services_Hide',
        label: 'Partner_Bidding_Avail_Services_Hide'
    },
    //Services Bidding Success Stories
    {
        value: 'Services_Bidding_Success_Story_Read',
        label: 'Services_Bidding_Success_Story_Read'
    },
    {
        value: 'Services_Bidding_Success_Story_Write',
        label: 'Services_Bidding_Success_Story_Write'
    },
    {
        value: 'Services_Bidding_Success_Story_Hide',
        label: 'Services_Bidding_Success_Story_Hide'
    },
    // Bidding User Services
    {
        value: 'Bidding_User_Services_Read',
        label: 'Bidding_User_Services_Read'
    },
    {
        value: 'Bidding_User_Services_Write',
        label: 'Bidding_User_Services_Write'
    },
    {
        value: 'Bidding_User_Services_Hide',
        label: 'Bidding_User_Services_Hide'
    },
    // Bidding Partner Service Quotes
    {
        value: 'Bidding_Partner_Service_Quotes_Read',
        label: 'idding_Partner_Service_Quotes_Read'
    },
    {
        value: 'Bidding_Partner_Service_Quotes_Write',
        label: 'Bidding_Partner_Service_Quotes_Write'
    },
    {
        value: 'Bidding_Partner_Service_Quotes_Hide',
        label: 'Bidding_Partner_Service_Quotes_Hide'
    },
    // Bidding Partner Service Quote Comments
    {
        value: 'Bidding_Partner_Service_Quote_Comments_Read',
        label: 'Bidding_Partner_Service_Quote_Comments_Read'
    },
    {
        value: 'Bidding_Partner_Service_Quote_Comments_Write',
        label: 'Bidding_Partner_Service_Quote_Comments_Write'
    },
    {
        value: 'Bidding_Partner_Service_Quote_Comments_Hide',
        label: 'Bidding_Partner_Service_Quote_Comments_Hide'
    },
    // User_Ride_Biddings
    {
        value: 'User_Ride_Biddings_Read',
        label: 'User_Ride_Biddings_Read'
    },
    {
        value: 'User_Ride_Biddings_Write',
        label: 'User_Ride_Biddings_Write'
    },
    {
        value: 'User_Ride_Biddings_Hide',
        label: 'User_Ride_Biddings_Hide'
    },
    // Bidding_Ride_Vehicles
    {
        value: 'Bidding_Ride_Vehicles_Read',
        label: 'Bidding_Ride_Vehicles_Read'
    },
    {
        value: 'Bidding_Ride_Vehicles_Write',
        label: 'Bidding_Ride_Vehicles_Write'
    },
    {
        value: 'Bidding_Ride_Vehicles_Hide',
        label: 'Bidding_Ride_Vehicles_Hide'
    },
    // Bidding_Ride_Vehicle_Brands
    {
        value: 'Bidding_Ride_Vehicle_Brands_Read',
        label: 'Bidding_Ride_Vehicle_Brands_Read'
    },
    {
        value: 'Bidding_Ride_Vehicle_Brands_Write',
        label: 'Bidding_Ride_Vehicle_Brands_Write'
    },
    {
        value: 'Bidding_Ride_Vehicle_Brands_Hide',
        label: 'Bidding_Ride_Vehicle_Brands_Hide'
    },
    // Bidding_Ride_Brand_Vehicles
    {
        value: 'Bidding_Ride_Brand_Vehicles_Read',
        label: 'Bidding_Ride_Brand_Vehicles_Read'
    },
    {
        value: 'Bidding_Ride_Brand_Vehicles_Write',
        label: 'Bidding_Ride_Brand_Vehicles_Write'
    },
    {
        value: 'Bidding_Ride_Brand_Vehicles_Hide',
        label: 'Bidding_Ride_Brand_Vehicles_Hide'
    },
    // Delivery_Bidding_Quotes
    {
        value: 'Delivery_Bidding_Quotes_Read',
        label: 'Delivery_Bidding_Quotes_Read'
    },
    {
        value: 'Delivery_Bidding_Quotes_Write',
        label: 'Delivery_Bidding_Quotes_Write'
    },
    // Delivery_Bidding_Comments
    {
        value: 'Delivery_Bidding_Comments_Write',
        label: 'Delivery_Bidding_Comments_Write'
    },
    // Bidding_Ride_Vehicle_Commission
    {
        value: 'Bidding_Ride_Vehicle_Commission_Read',
        label: 'Bidding_Ride_Vehicle_Commission_Read'
    },
    {
        value: 'Bidding_Ride_Vehicle_Commission_Write',
        label: 'Bidding_Ride_Vehicle_Commission_Write'
    },
    {
        value: 'Bidding_Ride_Vehicle_Commission_Hide',
        label: 'Bidding_Ride_Vehicle_Commission_Hide'
    },
    // Bidding_Ride_Success_Stories
    {
        value: 'Bidding_Ride_Success_Stories_Read',
        label: 'Bidding_Ride_Success_Stories_Read'
    },
    {
        value: 'Bidding_Ride_Success_Stories_Write',
        label: 'Bidding_Ride_Success_Stories_Write'
    },
    {
        value: 'Bidding_Ride_Success_Stories_Hide',
        label: 'Bidding_Ride_Success_Stories_Hide'
    },
    // B2B_User_Products_Bidding
    {
        value: 'B2B_User_Products_Bidding_Read',
        label: 'B2B_User_Products_Bidding_Read'
    },
    {
        value: 'B2B_User_Products_Bidding_Write',
        label: 'B2B_User_Products_Bidding_Write'
    },
    {
        value: 'B2B_User_Products_Bidding_Hide',
        label: 'B2B_User_Products_Bidding_Hide'
    },
    // B2B_User_Products_Bidding_Quotes
    {
        value: 'B2B_User_Products_Bidding_Quotes_Read',
        label: 'B2B_User_Products_Bidding_Quotes_Read'
    },
    {
        value: 'B2B_User_Products_Bidding_Quotes_Write',
        label: 'B2B_User_Products_Bidding_Quotes_Write'
    },
    // B2B_User_Products_Bidding_Comments
    {
        value: 'B2B_User_Products_Bidding_Comments_Write',
        label: 'B2B_User_Products_Bidding_Comments_Write'
    },
    //Meepaisa zone
    {
        value: 'Meepaisa_Zone_Read',
        label: 'Meepaisa_Zone_Read'
    },
    {
        value: 'Meepaisa_Zone_Write',
        label: 'Meepaisa_Zone_Write'
    },
    {
        value: 'Meepaisa_Zone_Hide',
        label: 'Meepaisa_Zone_Hide'
    },
    //Meepaisa Zone Theme
    {
        value: 'Meepaisa_Zone_Theme_Read',
        label: 'Meepaisa_Zone_Theme_Read'
    },
    {
        value: 'Meepaisa_Zone_Theme_Write',
        label: 'Meepaisa_Zone_Theme_Write'
    },
    {
        value: 'Meepaisa_Zone_Theme_Hide',
        label: 'Meepaisa_Zone_Theme_Hide'
    },
    //Meepaisa Theme
    {
        value: 'Meepaisa_Theme_Management_Read',
        label: 'Meepaisa_Theme_Management_Read'
    },
    {
        value: 'Meepaisa_Theme_Management_Write',
        label: 'Meepaisa_Theme_Management_Write'
    },
    {
        value: 'Meepaisa_Theme_Management_Hide',
        label: 'Meepaisa_Theme_Management_Hide'
    },
    //Zone Theme Product
    {
        value: 'Zone_Theme_Product_Management_Read',
        label: 'Zone_Theme_Product_Management_Read'
    },
    {
        value: 'Zone_Theme_Product_Management_Write',
        label: 'Zone_Theme_Product_Management_Write'
    },
    {
        value: 'Zone_Theme_Product_Management_Hide',
        label: 'Zone_Theme_Product_Management_Hide'
    },
    // Razorpay_Management
    {
        value: 'Razorpay_Payments_Read',
        label: 'Razorpay_Payments_Read'
    },
    {
        value: 'Razorpay_Payments_Write',
        label: 'Razorpay_Payments_Write'
    },
    {
        value: 'Razorpay_Payments_Hide',
        label: 'Razorpay_Payments_Hide'
    },
    //Razorpay_Payments_History
    {
        value: 'Razorpay_Payments_History_Read',
        label: 'Razorpay_Payments_History_Read'
    },
    {
        value: 'Razorpay_Payments_History_Write',
        label: 'Razorpay_Payments_History_Write'
    },
    //Razorpay_Transaction
    {
        value: 'Razorpay_Transactions_Read',
        label: 'Razorpay_Transactions_Read'
    },
    {
        value: 'Razorpay_Transactions_Hide',
        label: 'Razorpay_Transactions_Hide'
    },
    // Meepaisa_Global_Coupons
    {
        value: 'Global_Coupon_Management_Read',
        label: 'Global_Coupon_Management_Read'
    },
    {
        value: 'Global_Coupon_Management_Write',
        label: 'Global_Coupon_Management_Write'
    },
    {
        value: 'Global_Coupon_Management_Hide',
        label: 'Global_Coupon_Management_Hide'
    },
    // Meepaisa_Global_History_Management
    {
        value: 'Global_Coupon_History_Management_Read',
        label: 'Global_Coupon_History_Management_Read'
    },
    //Email_Template
    {
        value: 'Email_Template_Read',
        label: 'Email_Template_Read'
    },
    {
        value: 'Email_Template_Write',
        label: 'Email_Template_Write'
    },
    {
        value: 'Email_Template_Hide',
        label: 'Email_Template_Hide'
    },
    //Settings
    {
        value: 'Settings_Management_Read',
        label: 'Settings_Management_Read'
    },
    {
        value: 'Settings_Management_Write',
        label: 'Settings_Management_Write'
    },
    {
        value: 'Settings_Management_Hide',
        label: 'Settings_Management_Hide'
    },

];